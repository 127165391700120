/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @file This file contains any functions relating to the alerts used within the application
 */

/**
 * Displays an alert popup with the specified message.
 *
 * @param {string} message - The message to display in the alert popup.
 * @returns {void}
 */
export function displayError (message: string): void {
  alert(message)
}
