/**
 * @file This file contains any functions related to creating vector layers
 *
 */

import type VectorSource from 'ol/source/Vector'
import type Map from 'ol/Map'
import type TileLayer from 'ol/layer/Tile'
import type Style from 'ol/style/Style'
import VectorLayer from 'ol/layer/Vector'

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { config } from '../utils/configExport'
import { checkIfConfigIndexHasValidLayerKeys } from '../utils/utils'
import { createDefaultNoFillFeatureStyle, createPointStyle } from '../events/featureStyling'
import { FeatureType } from '../enums/enums'

/**
 * Creates a Vector Layer from a Vector Source.
 *
 * @param {VectorSource} vectorSource - The Vector Source to be used as the layer's data source.
 *
 * @returns {VectorLayer} A Vector Layer configured with the specified Vector Source.
 */
export function createVectorLayerFromVectorSource (vectorSource: VectorSource, vectorLayerId: string, layer: ConfigDefinedLayer): VectorLayer {
  let featureStyle: Style

  if (layer.featureType === FeatureType.point) {
    featureStyle = createPointStyle(vectorLayerId, layer.primaryColor)
  } else {
    featureStyle = createDefaultNoFillFeatureStyle(vectorLayerId)
  }

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    name: vectorLayerId,
    style: featureStyle
  })

  const layerZIndex = config.mapZIndex.indexOf(vectorLayerId)
  vectorLayer.setZIndex(layerZIndex)
  return vectorLayer
}

/**
 * Retrieves an active VectorLayer from a given VectorLayer ID within a map object.
 *
 * @param {string} vectorLayerId - The ID of the VectorLayer to retrieve.
 * @param {Map} map - The map in which to search for the VectorLayer.
 * @returns {VectorLayer | null} The found VectorLayer or `null` if not found.
 */
export function getActiveVectorLayerFromVectorLayerId (vectorLayerId: string, map: Map): VectorLayer | null {
  let foundLayer = null
  map.getLayers().forEach(function (layer: VectorLayer) {
    if (layer instanceof VectorLayer && layer.get('name') === vectorLayerId) {
      foundLayer = layer
    }
  })
  return foundLayer
}

/**
 * Retrieve a layer configuration object based on its ID from the global configuration.
 *
 * @param {string} layerId - The ID of the layer for which to retrieve the configuration.
 * @returns {ConfigDefinedLayer} The layer configuration object.
 */
export function getLayerFromConfigWithLayerId (layerId: string): ConfigDefinedLayer {
  return config.layers[layerId]
}

export function checkIfVectorLayerExistsOnMap (layerId: string, map: Map): boolean {
  let isOnMap = false
  map.getLayers().forEach(function (layer: VectorLayer) {
    if (layer instanceof VectorLayer && layer.get('name') === layerId) {
      isOnMap = true
    }
  })
  return isOnMap
}

/**
 * Reorder layers on the provided map based on the specified configuration.
 * Layers are reordered according to their z-index values defined in the `mapZIndex` configuration.
 * The map is then re-rendered to reflect the new layer order.
 *
 * @param {Map} map - The OpenLayers map object to reorder layers on.
 * @returns {void}
 */
export function reorderLayersAccordingToConfig (map: Map): void {
  const layers = map.getLayers().getArray()

  const layerIdToLayerDict: Record<string, VectorLayer | TileLayer> = {}
  layers.forEach((layer: VectorLayer | TileLayer) => {
    layerIdToLayerDict[layer.get('name')] = layer
  })

  // Get the config defined index and add map to the beginning
  const mapZIndex: string[] = config.mapZIndex
  const indexIsValid = checkIfConfigIndexHasValidLayerKeys(mapZIndex)

  if (indexIsValid) {
    for (const [layerZIndex, layerId] of mapZIndex.entries()) {
      const layerExists = checkIfVectorLayerExistsOnMap(layerId, map)
      if (layerExists) {
        const layer: VectorLayer | TileLayer = layerIdToLayerDict[layerId]
        layer.setZIndex(layerZIndex)
      }
    }
  }
  map.render()
}
