/**
 * @file This file contains any functions related to creating vector sources
 *
 */

import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'

import { config } from '../utils/configExport'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { CachedGeoJsonStatus, CoordinateSystems } from '../enums/enums'
import { getVectorSourceFromIndexDB, getAndCacheGeoJsonUnderLayerIdKeyAsync, checkIfVectorSourceIsInIndexedDB } from './localStorage'
import { reorderLayersAccordingToConfig, createVectorLayerFromVectorSource } from './vectorLayers'
import { displayError } from '../utils/alert'
import { applyIdsToVectorLayersFeatures } from '../utils/utils'
import { createWFSVectorSource } from './wfsVectorSources'

/**
 * Creates a VectorSource from a GeoJSON object.
 *
 * @param {object} geoJson - The GeoJSON object containing geographic data.
 *
 * @returns {VectorSource} A VectorSource object containing features parsed from the GeoJSON data.
 */
function createVectorSourceFromGeoJsonObject (geoJson: object): VectorSource {
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geoJson,
      {
        dataProjection: CoordinateSystems.TurfJSDefault,
        featureProjection: CoordinateSystems.OpenLayersDefault
      })
  })
  return vectorSource
}

/**
 * Creates a Vector Source from a GeoJSON URL provided in a config-defined layer.
 *
 * @param {ConfigDefinedLayer} layer - The layer configuration object found within layers in config.ts.
 *
 * @returns {VectorSource} A Vector Source instance created from the GeoJSON URL.
 *
 */
function createVectorSourceFromGeoJsonURL (layer: ConfigDefinedLayer): VectorSource {
  const vectorSource = new VectorSource({
    url: layer.path,
    format: new GeoJSON()
  })
  return vectorSource
}

/**
 * Creates a cached Vector Source from a GeoJSON URL provided in a config-defined layer.
 * If the GeoJSON data is available in the browser's local storage cache, it will be used; otherwise,
 * it will be fetched from the URL and cached for future use if possible if not it will create a vector source
 * from the URL.
 *
 * @param {ConfigDefinedLayer} layer - The layer configuration object found within layers in config.ts.
 * @param {string} layerId - The unique identifier for the GeoJSON layer used for caching.
 * @param {number} dataVersion - The version of the vector data.
 *
 * @returns {VectorSource | boolean} A Promise that resolves to either a Vector Source instance created from the GeoJSON data, or `false`
 * if loading or caching the GeoJSON data failed.
 *
 */
export async function createCachedVectorSource (layer: ConfigDefinedLayer, layerId: string, dataVersion: number): Promise<boolean | VectorSource> {
  // We need to check inside our browser db for the vector source first
  const cachedGeoJsonStatus = await checkIfVectorSourceIsInIndexedDB(layerId)

  if (cachedGeoJsonStatus === CachedGeoJsonStatus.cached) {
    const cachedGeoJson = await getVectorSourceFromIndexDB(layerId)
    const vectorSource = createVectorSourceFromGeoJsonObject(cachedGeoJson)
    return vectorSource
  }
  // First check if browser db is supported
  if (cachedGeoJsonStatus === CachedGeoJsonStatus.indexDBNotSupported) {
    const vectorSource = createVectorSourceFromGeoJsonURL(layer)
    return vectorSource
  }
  // We want to first cache the object under the layer ID and then create our vector source
  const isCached = getAndCacheGeoJsonUnderLayerIdKeyAsync(layer, layerId, dataVersion)

  if (await isCached) {
    const cachedGeoJson = await getVectorSourceFromIndexDB(layerId)
    const vectorSource = createVectorSourceFromGeoJsonObject(cachedGeoJson)
    return vectorSource
  }
  return false
}

/**
 * Creates and returns a dictionary of VectorSource objects for the configured layers. If an error occurs
 * a popup is shown telling the user to refresh
 *
 * @returns {Record<string, VectorSource>} A Promise that resolves to a dictionary where keys are layer IDs and values
 * are VectorSource objects.
 */
export async function createVectorSourceDict (vectorSourceDict: Record<string, VectorSource>): Promise<Record<string, VectorSource>> {
  for (const layerId in config.layers) {
    const layer: ConfigDefinedLayer = config.layers[layerId]
    if (layer.isVisible) {
      vectorSourceDict = await addVectorSourceToVectorSourceDict(vectorSourceDict, layerId)
    }
  }
  return vectorSourceDict
}

/**
 * Initializes vector sources within a dictionary and adds corresponding vector layers to a map.
 *
 * @param {Record<string, VectorSource>} vectorSourceDict - A dictionary of vector sources, where keys are source identifiers and values are VectorSource objects.
 * @param {Map} map - The open layers map to which vector layers will be added.
 *
 * @returns {void}
 *
 */
export function initVectorSourcesWithinDict (vectorSourceDict: Record<string, VectorSource>, map: Map): void {
  for (const layerId in vectorSourceDict) {
    initSingleVectorSourceFromDict(vectorSourceDict, layerId, map)
  }
  reorderLayersAccordingToConfig(map)
}

export function initSingleVectorSourceFromDict (vectorSourceDict: Record<string, VectorSource>, layerId: string, map: Map): void {
  const vectorSource = vectorSourceDict[layerId]
  const layer: ConfigDefinedLayer = config.layers[layerId]
  const vectorLayer = createVectorLayerFromVectorSource(vectorSource, layerId, layer)
  applyIdsToVectorLayersFeatures(vectorLayer, layerId)
  map.addLayer(vectorLayer)
}

/**
 * Adds a vector source the dictionary of vector sources. Depending on the layers configuration a different vector source will be created
 *
 * @param {Record<string, VectorSource>} vectorSourceDict - A dictionary of vector sources, where the keys are layerIds and values are VectorSource objects.
 * @param {string} layerId - The unique identifier for the layer.
 *
 * @returns {Promise<Record<string, VectorSource>>} A Promise that resolves to the updated dictionary of vector sources.
 *
 */
export async function addVectorSourceToVectorSourceDict (vectorSourceDict: Record<string, VectorSource>, layerId: string): Promise<Record<string, VectorSource>> {
  const layer: ConfigDefinedLayer = config.layers[layerId]
  if (layer.isWfs) {
    const vectorSource = createWFSVectorSource(layer, layerId)
    vectorSourceDict[layerId] = vectorSource
  } else {
    const vectorSource = await createCachedVectorSource(layer, layerId, config.dataVersion)
    if (typeof vectorSource === 'boolean') {
      // An error has occured on init we should show popup
      displayError(`Sorry, the ${layerId} data could not be downloaded. Please close this message and refresh the view in your browser to try again.`)
    } else {
      vectorSourceDict[layerId] = vectorSource
    }
  }
  return vectorSourceDict
}
