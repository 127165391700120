/**
 * @file This file contains any functions relating to the creation of the breakdown table.
 */

import { FeatureType, domIds } from '../../enums/enums'
import { resultData } from '../../events/resultDataCalculation'
import type { TableConfigObject } from '../../structures/interfaces'
import { config } from '../../utils/configExport'
import { checkIfDataIsTopLevelExcludingYear, createColumnAndHeaderList, createDutyLookupDict, createModeLookupDict, getDescriptionForDutyId, getDescriptionForModeId, getLayerIdsByType } from '../../utils/utils'
import { createTextContentForResultTable } from '../results'

/**
 * Builds breakdown container div and populates tables within.
 *
 * @returns {void}
 */
export function buildBreakdownSection (): HTMLDivElement {
  const breakdownContainer = document.createElement('div')
  breakdownContainer.id = domIds.clusterBreakdownContainer

  createBreakdownTables()
  return breakdownContainer
}

/**
 * Creates breakdown tables with hidden rows and appends them to the breakdown container
 *
 * @returns {void}
 */
export function createBreakdownTables (): void {
  const [breakdownColumns, breakdownHeaders] = createColumnAndHeaderList(config.resultsConfiguration.breakdownResultSection)

  const targetContainer = document.getElementById(domIds.clusterBreakdownContainer)
  const sectionContainer = document.createElement('div')

  // Note this will need to be changed if there are multiple cluster layers with different data levels
  const clusterLayers = getLayerIdsByType(FeatureType.cluster)
  // Guard against an empty layer configuration (which in turn disables any breakdown)
  // While only the first cluster layer is checked, in practice all cluster have data at deeper levels
  const dataIsTopLevel = ((clusterLayers.length > 0) ? checkIfDataIsTopLevelExcludingYear(clusterLayers[0]) : true)

  const modeLookupDict = createModeLookupDict()
  const dutyLookupDict = createDutyLookupDict()

  // Clear breakdown tables before attempting to populate
  clearBreakdownTables()
  if (!dataIsTopLevel && Object.keys(resultData.clusterBreakdown).length > 0) {
    const modesSorted = getDisplayOrderedModeOrDutyKeys(config.advancedControl.modes, true)
    const dutiesSorted = getDisplayOrderedModeOrDutyKeys(config.advancedControl.definedDuties, false)
    const breakdownTitle = document.createElement('h2')
    breakdownTitle.textContent = config.resultsConfiguration.breakdownResultSection.title
    const breakdownSubtitle = document.createElement('p')
    breakdownSubtitle.textContent = config.resultsConfiguration.breakdownResultSection.subtitle

    const entireBreakdownTable = document.createElement('table')
    const breakdownTableHeader = document.createElement('thead')
    breakdownTableHeader.id = domIds.clusterBreakdownTableHeader
    const breakdownTableHeaderRow = document.createElement('tr')
    const breakdownTableBody = document.createElement('tbody')
    breakdownTableBody.id = domIds.clusterBreakdownTableBody

    breakdownHeaders.forEach((header: string) => {
      const headerEntry = document.createElement('th')
      headerEntry.textContent = header
      breakdownTableHeaderRow.appendChild(headerEntry)
    })
    breakdownTableHeader.appendChild(breakdownTableHeaderRow)
    entireBreakdownTable.appendChild(breakdownTableHeader)

    // Order modes alphabetically
    for (const modeId of modesSorted) {
      if (!(modeId in resultData.clusterBreakdown)) continue
      const modeTableHeaderRow = document.createElement('tr')
      const modeTableHeaderEntry = document.createElement('th')
      const modeTitleSummary = document.createElement('summary')
      modeTitleSummary.title = config.resultsConfiguration.breakdownResultSection.subsectionHelpText
      const modeTitleDetails = document.createElement('details')
      modeTitleSummary.innerText = modeLookupDict[modeId]
      modeTitleDetails.appendChild(modeTitleSummary)

      // Create sr-only div for detail element
      const srOnlyElement = document.createElement('div')
      srOnlyElement.className = 'sr-only'
      srOnlyElement.textContent = getDescriptionForModeId(modeId)
      modeTitleDetails.appendChild(srOnlyElement)
      modeTableHeaderEntry.colSpan = breakdownHeaders.length
      modeTableHeaderEntry.appendChild(modeTitleDetails)
      modeTableHeaderRow.appendChild(modeTableHeaderEntry)
      breakdownTableBody.appendChild(modeTableHeaderRow)

      // Create hidden row for mode explanation
      const hiddenModeExplanationRow = document.createElement('tr')
      hiddenModeExplanationRow.style.display = 'none'
      modeTitleSummary.addEventListener('click', () => {
        if (hiddenModeExplanationRow.style.display === 'none') {
          hiddenModeExplanationRow.style.display = 'table-row'
        } else {
          hiddenModeExplanationRow.style.display = 'none'
        }
      })

      const modeDescriptionCell = document.createElement('td')
      modeDescriptionCell.colSpan = breakdownHeaders.length
      modeDescriptionCell.textContent = getDescriptionForModeId(modeId)
      hiddenModeExplanationRow.appendChild(modeDescriptionCell)
      breakdownTableBody.appendChild(hiddenModeExplanationRow)

      const dutyData = resultData.clusterBreakdown[modeId]

      // Order duties alphabetically
      for (const dutyId of dutiesSorted) {
        if (!(dutyId in dutyData)) continue
        const nestedBreakdownData = dutyData[dutyId]
        const breakdownDataRow = document.createElement('tr')
        const dutyEntry = document.createElement('td')

        const dutySummaryElement = document.createElement('summary')
        dutySummaryElement.title = config.resultsConfiguration.breakdownResultSection.subsectionHelpText
        const dutyDetailsElement = document.createElement('details')
        dutySummaryElement.textContent = dutyLookupDict[dutyId]
        dutyDetailsElement.appendChild(dutySummaryElement)
        const srOnlyElement = document.createElement('div')
        srOnlyElement.className = 'sr-only'
        srOnlyElement.textContent = getDescriptionForDutyId(dutyId)
        dutyDetailsElement.appendChild(srOnlyElement)

        dutyEntry.appendChild(dutyDetailsElement)
        breakdownDataRow.appendChild(dutyEntry)
        breakdownColumns.forEach((column: string) => {
          const entriesConfig: TableConfigObject = config.resultsConfiguration.breakdownResultSection.tableConfig[column]
          const textContent = createTextContentForResultTable(entriesConfig, nestedBreakdownData[column])
          const breakdownDataEntry = document.createElement('td')
          breakdownDataEntry.textContent = textContent
          breakdownDataRow.appendChild(breakdownDataEntry)
        })

        // Create hidden explanation row
        const hiddenDutyExplanationRow = document.createElement('tr')
        hiddenDutyExplanationRow.style.display = 'none'
        const hiddenDutyExplanationEntry = document.createElement('td')
        hiddenDutyExplanationEntry.colSpan = breakdownHeaders.length
        // Place lookup here
        hiddenDutyExplanationEntry.textContent = getDescriptionForDutyId(dutyId)
        hiddenDutyExplanationRow.appendChild(hiddenDutyExplanationEntry)

        dutySummaryElement.addEventListener('click', () => {
          if (hiddenDutyExplanationRow.style.display === 'none') {
            hiddenDutyExplanationRow.style.display = 'table-row'
          } else {
            hiddenDutyExplanationRow.style.display = 'none'
          }
        })

        breakdownTableBody.appendChild(breakdownDataRow)
        breakdownTableBody.appendChild(hiddenDutyExplanationRow)
      }
    }
    entireBreakdownTable.appendChild(breakdownTableBody)
    sectionContainer.appendChild(breakdownTitle)
    sectionContainer.appendChild(breakdownSubtitle)
    sectionContainer.appendChild(entireBreakdownTable)
  }

  if (targetContainer !== null) targetContainer.appendChild(sectionContainer)
}

/**
 * Clears entire breakdown container
 *
 * @returns {void}
 */
export function clearBreakdownTables (): void {
  const breakdownContainer = document.getElementById(domIds.clusterBreakdownContainer)

  if (breakdownContainer !== null) {
    breakdownContainer.innerHTML = ''
  }
}

/**
 * Gets a list of mode or duty keys in visual display order
 *
 * @param modeOrDutyData - Dictionary of modes or duties, each value including a displayName key and value
 * @param isMode - True if mode data, where key is the propertyColumn
 * @returns {any[string]} List of keys in display order
 */
function getDisplayOrderedModeOrDutyKeys (modeOrDutyData: Record<string, any>, isMode: boolean = false): any[string] {
  const forSorting: any[] = []
  for (const modeOrDuty in modeOrDutyData) {
    if (isMode) {
      // Breakdown mode data is keyed by column reference
      forSorting.push([modeOrDutyData[modeOrDuty]?.propertyColumn, modeOrDutyData[modeOrDuty]?.displayName])
    } else {
      // While duties are keyed self
      forSorting.push([modeOrDuty, modeOrDutyData[modeOrDuty]?.displayName])
    }
  }
  forSorting.sort(function (a, b) {
    return a[1] - b[1]
  })

  const outputKeys: any[string] = []
  forSorting.forEach((sortedData) => outputKeys.push(sortedData[0]))
  return outputKeys
}
