/**
 * @file This file contains any utility functions used across the codebase
 *
 */
import Papa from 'papaparse'

import type { Feature } from 'ol'
import type VectorLayer from 'ol/layer/Vector'
import GeoJSON from 'ol/format/GeoJSON'

import { getMinimumCertainty } from '../processing/renderingCalculations'
import { getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import type { StructureWithinGeoJsonProperties, ConfigDefinedLayer, TableConfigObject } from '../structures/interfaces'
import { config } from '../utils/configExport'
import { FeatureType, featurePropertyParameters } from '../enums/enums'

/**
 * Checks if one of the provided active modes are keys within the dataToCheck.
 *
 * @param {string[]} activeModes - An array of mode names to check.
 * @param {unknown} dataToCheck - An object (JSON) to check for the presence of active modes.
 * @returns {boolean} True if all active modes are keys within currentYearData, false otherwise.
 */
export function checkIfActiveModesIsInFeatureProperties (activeModes: string[], dataToCheck: unknown): boolean {
  if (activeModes.length === 0) {
    return false
  }
  return activeModes.some(mode => mode in dataToCheck)
}

/**
 * Checks if the provided config indexes are valid
 *
 * @param {string[]} indexToCheck - The config index to check
 * @returns {boolean} True if the config index is valid false if not.
 */
export function checkIfConfigIndexHasValidLayerKeys (indexToCheck: string[]): boolean {
  return indexToCheck.every((key: string) => key in config.layers)
}

/**
 * Retrieves a list of active modes based on the checked checkboxes.
 *
 * @returns {string[]} An array of the active modes.(In their short form)
 */
export function getActiveModesList (): string[] {
  const modesDict = config.advancedControl.modes
  const modeList: string[] = []
  for (const modeId in modesDict) {
    const modeCheckBox: HTMLInputElement = document.getElementById(modeId)
    if (modeCheckBox?.checked) {
      modeList.push(modesDict[modeId].propertyColumn)
    }
  }
  return modeList
}

/**
 * Check if the data structure within the specified layer configuration is considered top-level
 * It does this by checking the values within the structureWithinGeoJsonProperties parameter found in config
 * If duties & modes are false the data is considered top level.
 *
 * @param {string} layerId - The ID of the layer configuration to check.
 * @returns {boolean} `true` if the data is top-level excluding "year," `false` otherwise.
 */
export function checkIfDataIsTopLevelExcludingYear (layerId: string): boolean {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const layersGeoJsonStructure: StructureWithinGeoJsonProperties = layer.structureWithinGeoJsonProperties

  switch (true) {
    case !layersGeoJsonStructure.duties && !layersGeoJsonStructure.modes:
      return true
    default:
      return false
  }
}

/**
 * Filters geoJSON properties based on minimum certainty and layer structure.
 *
 * @param {any} geoJsonProperties - The geoJSON properties to filter.
 * @param {string} layerId - The ID of the layer to which the properties belong.
 * @returns {any} The filtered geoJSON properties.
 */
export function filterGeoJsonPropertiesForMinimumCertainty (geoJsonProperties: any, layerId: string): any {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const dataIsTopLevel: boolean = checkIfDataIsTopLevelExcludingYear(layerId)
  const minimumCertainty = getMinimumCertainty()
  const filteredData: any = {}

  // If the data is top level we only need to filter for mimimum certainty
  if (dataIsTopLevel) {
    const certainty = geoJsonProperties[layer.columnsToReference.certaintyPercentage]
    if (certainty >= minimumCertainty) {
      filteredData[layer.columnsToReference.certaintyPercentage] = certainty
      filteredData[layer.columnsToReference.hydrogenDemand] = geoJsonProperties[layer.columnsToReference.hydrogenDemand]
    }
    return filteredData
  }

  // The layer has only either mode or duties
  for (const propertyKey in geoJsonProperties) {
    // the layer has both mode and duties
    const nestedData = geoJsonProperties[propertyKey]
    if (layer.structureWithinGeoJsonProperties.duties) {
      for (const duty in nestedData) {
        const dutyNestedData = nestedData[duty]
        const certainty = dutyNestedData[layer.columnsToReference.certaintyPercentage]
        if (certainty >= minimumCertainty) {
          if (!(propertyKey in filteredData)) filteredData[propertyKey] = {}
          filteredData[propertyKey][duty] = {
            [layer.columnsToReference.certaintyPercentage]: certainty,
            [layer.columnsToReference.hydrogenDemand]: dutyNestedData[layer.columnsToReference.hydrogenDemand]
          }
        }
      }
    } else {
      const certainty = nestedData[layer.columnsToReference.certaintyPercentage]
      if (certainty >= minimumCertainty) {
        filteredData[propertyKey] = {
          [layer.columnsToReference.certaintyPercentage]: certainty,
          [layer.columnsToReference.hydrogenDemand]: nestedData[layer.columnsToReference.hydrogenDemand]
        }
      }
    }
  }
  return filteredData
}

/**
 * Assigns unique identifiers to features in a vector layer and sets the layerId within each feature.
 *
 * @param {VectorLayer} vectorLayer - The vector layer to which identifiers are applied.
 * @param {string} layerId - The identifier for the vector layer.
 * @returns {void}
 */
export function applyIdsToVectorLayersFeatures (vectorLayer: VectorLayer, layerId: string): void {
  let featureCount = 0
  vectorLayer.getSource().getFeatures().forEach(function (feature: Feature) {
    featureCount += 1
    const featureId = `${layerId}_${featureCount}`
    feature.setId(featureId)
    feature.set(featurePropertyParameters.layerId, layerId)
  })
}

/**
 * Iterates through all configured layers and collects layer IDs associated with the specified feature type.
 *
 * @returns {string[]} - An array of layer IDs that are of one single featureType.
 */
export function getLayerIdsByType (featureType: FeatureType): string[] {
  const allLayers = config.layers
  const layerIdsToReturn: string[] = []

  for (const layerId in allLayers) {
    const layer: ConfigDefinedLayer = allLayers[layerId]

    if (layer.featureType === featureType) layerIdsToReturn.push(layerId)
  }
  return layerIdsToReturn
}

/**
 * Extracts coordinates from a given feature's geometry.
 * Returns the coordinates as an array.
 *
 * @param {Feature} feature - The feature from which coordinates will be extracted.
 * @returns {any[]} - An array containing the extracted coordinates from the feature.
 */
export function getCoordinatesFromFeature (feature: Feature): any[] {
  const geometry = feature.getGeometry()
  const geoJsonFormat = new GeoJSON()
  const featuresGeoJsonsObject = geoJsonFormat.writeGeometryObject(geometry)
  const coordinates: any[] = featuresGeoJsonsObject.coordinates
  return coordinates
}

/**
 * Creates a list of layer IDs used for proximity analysis from the configuration.
 *
 * @returns {string[]} An array containing layer IDs designated for proximity analysis.
 */
export function createProximityLayerList (): string[] {
  const proximityLayerList: string[] = []
  const allLayers = config.layers
  for (const layerId in allLayers) {
    const layerConfig = getLayerFromConfigWithLayerId(layerId)
    if (layerConfig.isUsedForProximityAnalysis && layerConfig.featureType !== FeatureType.cluster) proximityLayerList.push(layerId)
  }
  return proximityLayerList
}

/**
 * Retrieves the description associated with a given mode ID from the configuration.
 *
 * @param {string} modeId - The mode ID for which to retrieve the description.
 * @returns {string | null} - The description of the mode, or null if not found.
 */
export function getDescriptionForModeId (modeId: string): string | null {
  const allModes = config.advancedControl.modes

  for (const mode in allModes) {
    const modeDetails = allModes[mode]
    if (modeDetails.propertyColumn === modeId) return modeDetails.description
  }
  return null
}

/**
 * Retrieves the description associated with a given duty ID from the configuration.
 *
 * @param {string} modeId - The duty ID for which to retrieve the description.
 * @returns {string | null} - The description of the duty, or null if not found.
 */
export function getDescriptionForDutyId (dutyId: string): string | null {
  const allDuties = config.advancedControl.definedDuties

  for (const dutyPropertyId in allDuties) {
    if (dutyPropertyId === dutyId) return allDuties[dutyPropertyId].description
  }
  return null
}

/**
 * Rounds a given number to a specified number of decimal places.
 *
 * @param {number} numberToRound - The number to be rounded.
 * @param {number} amountOfDecimalPoints - The number of decimal places to round to.
 * @returns {number} - The rounded number.
 */
export function roundToDecimalPlaces (numberToRound: number, amountOfDecimalPoints: number): number {
  const factor = Math.pow(10, amountOfDecimalPoints)
  return Math.round(numberToRound * factor) / factor
}

/**
 * Creates a column list and header list based on the provided result section's table configuration.
 *
 * @param {Object} resultSection - The result section containing the table configuration.
 * @returns {string[][]} An array containing two elements: the column list and the header list.
 */
export function createColumnAndHeaderList (resultSection: any): string[][] {
  const tableConfig = resultSection.tableConfig

  const columnList = []
  const headerList = []
  for (const columnToReference in tableConfig) {
    const columnConfigData: TableConfigObject = tableConfig[columnToReference]
    headerList.push(columnConfigData.displayName)
    if (columnConfigData.isColumnToReference) {
      columnList.push(columnToReference)
    }
  }
  return [columnList, headerList]
}

/**
 * Converts an array of Json's into a CSV that is downloaded for the user
 *
 * @param {any[]} JsonArrayToConvert - The array of json objects to turn into a CSV
 * @param {string} filename - The filename for the downloaded CSV
 * @returns {void}
 */
export function turnArrayOfJsonToCSVDownload (JsonArrayToConvert: any[], filename: string): void {
  const csv = Papa.unparse(JsonArrayToConvert)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.role = 'link'
  link.ariaLabel = `Download ${filename}`
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * This function takes in a json and a filename and downloads it for the user
 *
 * @param {Record<string, unknown>} jsonToDownload - The json the user will download
 * @param {string} filename - The filename for the downloaded json
 * @returns {void}
 */
export function downloadJson (jsonToDownload: Record<string, unknown>, filename: string): void {
  // Stringify the JSON and use 2 spaces for indentation
  const jsonStr = JSON.stringify(jsonToDownload, null, 2)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.role = 'link'
  link.ariaLabel = `Download ${filename}`
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  createModeLookupDict()
}

/**
 * Creates a dictionary to look up mode names from cluster data to convert them into human readable ones.
 *
 * @returns {Record<string, string>} The dictionary containing mode names and their respective display names.
 */
export function createModeLookupDict (): Record<string, string> {
  const lookupDict: Record<string, string> = {}
  const allModes = config.advancedControl.modes

  for (const modeId in allModes) {
    const modeData = allModes[modeId]
    lookupDict[modeData.propertyColumn] = modeData.displayName
  }
  return lookupDict
}

/**
 * Creates a dictionary to look up duty names from cluster data to convert them into human readable ones.
 *
 * @returns {Record<string, string>} The dictionary containing duty names and their respective display names.
 */
export function createDutyLookupDict (): Record<string, string> {
  const lookupDict: Record<string, string> = {}
  const allModes = config.advancedControl.definedDuties

  for (const dutyId in allModes) {
    const dutyData = allModes[dutyId]
    lookupDict[dutyId] = dutyData.displayName
  }
  return lookupDict
}
