/**
 * @file This file contains any functions relating to the config defined sliders events.
 */
import type { Map, Feature } from 'ol'

import { checkIfVectorLayerExistsOnMap, getActiveVectorLayerFromVectorLayerId } from '../processing/vectorLayers'
import { getLayerIdsByType } from '../utils/utils'
import { recalculateFeaturesProperties } from '../processing/renderingCalculations'
import { FeatureType, sliderIds } from '../enums/enums'
import { config } from '../utils/configExport'
import { removeSelectionsThatAreInvisible, styleAllSelectionsWithinSelectionDict } from './selectionEvents'
import { filterSelectedClusters } from './selectionCalculation'

/**
 * Update cluster features on the provided map based on layer configurations and data.
 *
 * This function will check layers in config that have property data present - Iterate through the layers
 * if they are present on the map this function will recalculate the certainty and demand based on control
 * selections.
 *
 * @param {Map} map - The map object on which to update clusters.
 * @returns {void}
 */
export function updateClustersOnMap (map: Map): void {
  const layers = getLayerIdsByType(FeatureType.cluster)

  for (const layerId of layers) {
    const layerExists = checkIfVectorLayerExistsOnMap(layerId, map)
    if (layerExists) {
      const vectorLayer = getActiveVectorLayerFromVectorLayerId(layerId, map)
      // there are multiple features in this vector layer we want to look through them
      vectorLayer.getSource().getFeatures().forEach(function (feature: Feature) {
        const featureId = feature.getId()
        recalculateFeaturesProperties(feature, layerId, featureId)
      })
      removeSelectionsThatAreInvisible()
      styleAllSelectionsWithinSelectionDict(map)
      filterSelectedClusters(map)
      map.render()
    }
  }
}

/**
 * Get the maximum range based on the state of a slider element.
 *
 * @returns {number} The maximum range. Returns the default if the slider is not active.
 */
export function getMaximumRange (): number {
  const rangeSlider = document.getElementById(sliderIds.range)
  const detailsElement = document.getElementById(`details_${sliderIds.range}`)

  if (rangeSlider instanceof HTMLInputElement && detailsElement instanceof HTMLDetailsElement) {
    const range: number = parseInt(rangeSlider.value)
    return range
  }
  // Return the default value found in config
  return config.advancedControl.customSliders[sliderIds.range].defaultValue
}
