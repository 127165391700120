import { Overlay } from 'ol'
import type { Feature, MapBrowserEvent } from 'ol'
import type VectorLayer from 'ol/layer/Vector'

import { domIds, featurePropertyParameters } from '../enums/enums'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { getLayerFromConfigWithLayerId } from './vectorLayers'
import { getLayerIdsThatAreVisible } from '../events/layerEvents'
import { roundToDecimalPlaces } from '../utils/utils'
import { config } from '../utils/configExport'

/**
 * Applies a tooltip overlay to the map object.
 *
 * A user can now hover over specific layers and see data about that feature within the layer
 *
 * @param {Map} map - The map in which the user is hovering over.
 * @returns {void}
 */
export function applyToolTipOverlay (map: Map): void {
  const tooltip = document.getElementById('tooltip')

  const overlay = new Overlay({
    element: tooltip,
    offset: [10, 0],
    positioning: 'bottom-left'
  })

  map.addOverlay(overlay)

  const displayTooltip = (evt: MapBrowserEvent): void => {
    const pixel = evt.pixel
    const visibleLayers: string[] = getLayerIdsThatAreVisible()
    const layerId = getLayerNameMouseIsHoveringOver(map, pixel, visibleLayers)
    const feature: Feature = getFeatureMouseIsHoveringOver(map, pixel, visibleLayers)
    const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
    if (tooltip instanceof HTMLElement) {
      if (feature === undefined) {
        tooltip.style.display = 'none'
      } else {
        tooltip.style.display = 'block'
      }
      if (layerId !== domIds.map && feature !== null && visibleLayers.includes(layerId) &&
          evt.originalEvent.target.tagName.toLowerCase() === 'canvas') {
        // Canvas occurs only when the map is the highest element
        // False (allowing tooltip to be hidden) when under a control
        overlay.setPosition(evt.coordinate)
        const tooltipLines: string[] = []

        if (layer?.displayName !== undefined && layer?.displayName !== null) tooltipLines.push(`${layer.displayName}:`)
        if (layer?.columnsToReference?.displayNameColumn !== undefined &&
            layer?.columnsToReference?.displayNameColumn !== null) {
          tooltipLines.push(feature.get(layer?.columnsToReference?.displayNameColumn))
        }
        // Demand context provided by displayName where relevant
        const hydrogenDemand: number = feature.get(featurePropertyParameters.hydrogenDemand)
        if (hydrogenDemand !== undefined && hydrogenDemand !== null && !isNaN(hydrogenDemand) && layer.displayUnits !== undefined) {
          // Reduce spurious accuracy to tens of kg
          // FUTURE: Consider a number comma formatting function for 4+ figure demands, if required
          const roundedDemand = roundToDecimalPlaces(hydrogenDemand, config.toolTipHydrogenDemandRoundingDecimalAccuracy)
          tooltipLines.push(`${roundedDemand} ${layer.displayUnits}`)
        }
        const certaintyPercentage = feature.get(featurePropertyParameters.rawCertaintyPercentage)
        if (certaintyPercentage !== undefined && certaintyPercentage !== null && !isNaN(certaintyPercentage)) {
          // Multiply by 100 to get human readable percentage & apply config based certainty tooltip rounding
          const roundedCertainty = roundToDecimalPlaces((certaintyPercentage * 100), config.toolTipCertaintyRoundingDecimalAccuracy)
          tooltipLines.push(`${roundedCertainty}% certainty`)
        }
        // FUTURE: Could be extended to handle other value structures

        if (tooltipLines.length > 0) tooltip.innerHTML = tooltipLines.join('<br />')
      } else {
        tooltip.style.display = 'none'
      }
    }
  }

  map.on('pointermove', displayTooltip)
}

/**
 * Retrieves the layerId for a feature the users mouse is hovering over if the layer is visible.
 *
 * @param {any} pixel - The pixel object (contains data to let the function know where the users mouse is located).
 * @param {Map} map - The map in which the user is hovering over.
 * @param {string[]} visibleLayers - The map in which the user is hovering over.
 * @returns {string} The layerId.
 */
function getLayerNameMouseIsHoveringOver (map: Map, pixel: any, visibleLayers: string[]): string {
  let layerName = ''
  map.forEachFeatureAtPixel(
    pixel,
    function (feature: Feature) {
      const featuresLayerId = feature.get(featurePropertyParameters.layerId)
      if (visibleLayers.includes(featuresLayerId)) return feature
    },
    {
      layerFilter: function (layer: VectorLayer) {
        // FUTURE: Also test for visibility
        layerName = layer.get('name')
        return layer
      }
    }
  )
  return layerName
}

function getFeatureMouseIsHoveringOver (map: Map, pixel: any, visibleLayers: string[]): Feature | undefined {
  let foundFeature: Feature
  map.forEachFeatureAtPixel(
    pixel,
    function (feature: Feature) {
      const featuresLayerId = feature.get(featurePropertyParameters.layerId)
      if (visibleLayers.includes(featuresLayerId)) {
        foundFeature = feature
        return feature
      }
    }
  )
  return foundFeature
}
