/**
 * @file This file contains any rendering calculation related functions
 *
 */

import type { Feature } from 'ol'
import type { Style } from 'ol/style'

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { config } from '../utils/configExport'
import { getLayerFromConfigWithLayerId } from './vectorLayers'
import { checkIfActiveModesIsInFeatureProperties, checkIfDataIsTopLevelExcludingYear, filterGeoJsonPropertiesForMinimumCertainty, getActiveModesList } from '../utils/utils'
import { FeatureType, featurePropertyParameters, sliderIds } from '../enums/enums'
import { getCurrentYearDisplayed } from '../controls/yearControls'
import { createDefaultClusterStyle } from '../events/featureStyling'

/**
 * This function calculates the certainty percentage based on the provided
 * yearFilteredGeoJsonProperties. It can handle the data as long as it conforms to our structureWithinGeoJsonProperties it
 * checks if the data is at the top level (excluding year as this will only be called from a year change event which has
 * already filtered for year). If the data is not in the top level it will check for duty and then mode or a combination of both
 *
 * @param {unknown} yearFilteredGeoJsonProperties - The feature-specific data for calculations.
 * @param {number} totalDemand - The total hydrogen demand used in calculations.
 * @param {string} layerId - The ID of the layer to calculate certainty for.
 * @returns {number} The calculated certainty percentage.
 */
export function calculateCertainty (yearFilteredGeoJsonProperties: any, totalDemand: number, layerId: string): number {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const activeModes = getActiveModesList()
  let certaintyPercentage = 0
  const defaultCertainty = 1
  const modeIsInProperties: boolean = checkIfActiveModesIsInFeatureProperties(activeModes, yearFilteredGeoJsonProperties)
  const dataIsTopLevel: boolean = checkIfDataIsTopLevelExcludingYear(layerId)
  // Data is at the top level and can just be accessed
  if (dataIsTopLevel) {
    const weightedAverage = calculateSingleFeaturesCertainty(yearFilteredGeoJsonProperties, totalDemand, layer)
    certaintyPercentage += weightedAverage
    return certaintyPercentage
  }

  if (layer.structureWithinGeoJsonProperties.modes) {
    if (modeIsInProperties) {
      // The data has modes within it and those modes are checked in our advanced controls
      for (const mode in yearFilteredGeoJsonProperties) {
        const modeData = yearFilteredGeoJsonProperties[mode]
        if (layer.structureWithinGeoJsonProperties.duties) {
          for (const duty in modeData) {
            // The data has modes and duties present
            const dataValues = modeData[duty]
            const weightedAverage = calculateSingleFeaturesCertainty(dataValues, totalDemand, layer)
            certaintyPercentage += weightedAverage
          }
        } else {
          // The data has just modes present
          const modeData = yearFilteredGeoJsonProperties[mode]
          const weightedAverage = calculateSingleFeaturesCertainty(modeData, totalDemand, layer)
          certaintyPercentage += weightedAverage
        }
      }
      return certaintyPercentage
    }
    return certaintyPercentage
  }

  // The property only has duties present
  if (layer.structureWithinGeoJsonProperties.duties) {
    for (const duty in yearFilteredGeoJsonProperties) {
      const dataValues = yearFilteredGeoJsonProperties[duty]
      const weightedAverage = calculateSingleFeaturesCertainty(dataValues, totalDemand, layer)
      certaintyPercentage += weightedAverage
    }
    // If nothing has been added to the certaintyPercentage we can just use the default
    if (certaintyPercentage === 0) {
      return defaultCertainty
    }
    // not sure what i average out here by how many duties there are?
    return certaintyPercentage
  }
}

/**
 * Calculate the weighted certainty for a specific mode/duty.
 *
 *
 * @param {any} data - The feature-specific data for calculations.
 * @param {number} totalDemand - The total hydrogen demand of the specific mode/duty.
 * @param {ConfigDefinedLayer} layer - The total config layer object containing column names.
 * @returns {number} The calculated sum of hydrogen demand.
 */
function calculateSingleFeaturesCertainty (data: any, totalDemand: number, layer: ConfigDefinedLayer): number {
  const hydrogenDemand: number = data[layer.columnsToReference.hydrogenDemand]
  const certainty: number = data[layer.columnsToReference.certaintyPercentage]
  const weightedAverage = (hydrogenDemand / totalDemand) * certainty
  return weightedAverage
}

/**
 * Calculate the sum of hydrogen demand for a specific feature.
 *
 * This function calculates the hydrogen demand based on the provided
 * yearFilteredGeoJsonProperties. It can handle the data as long as it conforms to our structureWithinGeoJsonProperties it
 * checks if the data is at the top level (excluding year as this will only be called from a year change event which has
 * already filtered for year). If the data is not in the top level it will check for duty and then mode or a combination of both
 *
 * @param {unknown} yearFilteredGeoJsonProperties - The feature-specific data for calculations.
 * @param {string} layerId - The ID of the layer to calculate hydrogen demand for.
 * @returns {number} The calculated sum of hydrogen demand.
 */
export function sumHydrogenDemand (yearFilteredGeoJsonProperties: any, layerId: string): number {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const activeModes = getActiveModesList()
  let hydrogenDemand = 0
  const modeIsInProperties: boolean = checkIfActiveModesIsInFeatureProperties(activeModes, yearFilteredGeoJsonProperties)
  const dataIsTopLevel: boolean = checkIfDataIsTopLevelExcludingYear(layerId)

  // Data is at the top level and can just be accessed
  if (dataIsTopLevel) {
    const demandToAdd: number = yearFilteredGeoJsonProperties[layer.columnsToReference.hydrogenDemand]
    hydrogenDemand += demandToAdd
    return hydrogenDemand
  }

  // Data has modes present
  if (layer.structureWithinGeoJsonProperties.modes) {
    if (modeIsInProperties) {
      // Data has duties and modes
      for (const mode in yearFilteredGeoJsonProperties) {
        const modeData = yearFilteredGeoJsonProperties[mode]
        if (layer.structureWithinGeoJsonProperties.duties) {
          for (const duty in modeData) {
            const dataValues = modeData[duty]
            const demandToAdd: number = dataValues[layer.columnsToReference.hydrogenDemand]
            hydrogenDemand += demandToAdd
          }
        } else {
          // Data has only modes
          const modeData = yearFilteredGeoJsonProperties[mode]
          const demandToAdd: number = modeData[layer.columnsToReference.hydrogenDemand]
          hydrogenDemand += demandToAdd
        }
      }
      return hydrogenDemand
    }
    return hydrogenDemand
  }

  // Data only has duties present
  if (layer.structureWithinGeoJsonProperties.duties) {
    for (const duty in yearFilteredGeoJsonProperties) {
      const dataValues = yearFilteredGeoJsonProperties[duty]
      const demandToAdd: number = dataValues[layer.columnsToReference.hydrogenDemand]
      hydrogenDemand += demandToAdd
    }
    return hydrogenDemand
  }
}

/**
 * Clean and sanitize a certainty value, ensuring it falls within a specified range.
 *
 * If the input certainty is less than 0, it is set to 0.
 * If the input certainty is between 0 and 0.2 (exclusive), it is set to 0.2.
 * If the input certainty is greater than 0.9, it is set to 0.9.
 * Otherwise, the input certainty is unchanged.
 *
 * This function will then check the minimum cetrainty selected from controls and ensure the
 * input certainty value is above this.
 *
 * @param {number} certainty - The certainty value to be cleaned and sanitized.
 * @returns {number} The cleaned and sanitized certainty value.
 */
export function cleanCertaintyValue (certainty: number): number {
  let cleanedCertainty: number = 0
  switch (true) {
    case (certainty < config.minimumCertainty):
      cleanedCertainty = config.minimumCertainty
      break
    case (certainty > config.maximumCertainty):
      cleanedCertainty = config.maximumCertainty
      break
    default:
      cleanedCertainty = config.minimumCertainty + ((config.maximumCertainty - config.minimumCertainty) * certainty)
      break
  }
  return cleanedCertainty
}

/**
 * Get the minimum certainty value based on the state of a slider element.
 *
 * @returns {number} The minimum certainty value. Returns 0 if the slider is not active.
 */
export function getMinimumCertainty (): number {
  const certaintySlider = document.getElementById(sliderIds.certainty)
  const detailsElement = document.getElementById(`details_${sliderIds.certainty}`)

  if (certaintySlider instanceof HTMLInputElement && detailsElement instanceof HTMLDetailsElement) {
    const unFormattedCurrentCertainty = certaintySlider.value
    const currentCertainty: number = parseInt(unFormattedCurrentCertainty)
    // Divide by 100 to make the value a percentage
    return (currentCertainty / 100)
  }
  // Return the default value found in config
  return config.advancedControl.customSliders[sliderIds.certainty].defaultValue
}

/**
 * Recalculates and updates the properties of a given feature based on layer-specific rules.
 *
 * It will apply cluster styling if the features geometry is a point if not it will not apply any styling
 *
 * @param {Feature} feature - The feature to recalculate properties for.
 * @param {string} layerId - The ID of the layer associated with the feature.
 * @returns {void}
 */
export function recalculateFeaturesProperties (feature: Feature, layerId: string, featureId: string): void {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  let properties = feature.getProperties()

  // If year data is present filter it
  if (layer.structureWithinGeoJsonProperties.year) {
    const currentYear = getCurrentYearDisplayed()
    const yearData = properties[config.layers[layerId].columnsToReference.year]
    properties = yearData?.[currentYear]
  }
  const filteredData = filterGeoJsonPropertiesForMinimumCertainty(properties, layerId)
  const hydrogenDemand = sumHydrogenDemand(filteredData, layerId)
  const certaintyPercentage = calculateCertainty(filteredData, hydrogenDemand, layerId)
  const cleandedCertaintyPercentage = cleanCertaintyValue(certaintyPercentage)

  // Save the calculation values in the features properties for use in the tooltip
  feature.set(featurePropertyParameters.hydrogenDemand, hydrogenDemand)
  feature.set(featurePropertyParameters.rawCertaintyPercentage, (certaintyPercentage))
  // Rounding to be handled visually

  // Save layerId to feature for access to the features layer config
  feature.set(featurePropertyParameters.layerId, layerId)
  feature.setId(featureId)

  if (layer.featureType === FeatureType.cluster) {
    const defaultClusterStyling: Style = createDefaultClusterStyle(hydrogenDemand, cleandedCertaintyPercentage, layerId)
    feature.setStyle(defaultClusterStyling)
  }
}
