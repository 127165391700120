/**
 * @file This file contains any functions related to interacting with the browsers local storage
 *
 */

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { CachedGeoJsonStatus, IndexDBStatus } from '../enums/enums'
import { config } from '../utils/configExport'

/**
 * Fetches GeoJSON data from a remote URL, caches it in the index DB under a specified layer identifier.
 *
 * @param {ConfigDefinedLayer} layer - The layer configuration object.
 * @param {string} layerId - The unique identifier for the layer.
 * @param {number} dataVersion - The version of the data under which to cache the GeoJSON.
 *
 * @returns {Promise<boolean>} A Promise that resolves to `true` if data was successfully fetched and cached, or `false` if there was an error.
 */
export async function getAndCacheGeoJsonUnderLayerIdKeyAsync (layer: ConfigDefinedLayer, layerId: string, dataVersion: number): Promise<boolean> {
  try {
    const response = await fetch(layer.path)
    if (response.ok) {
      const jsonData = await response.json()
      const vectorSourceStored = await storeVectorSourceInIndexDB(layerId, jsonData)
      if (vectorSourceStored) return true
      return false
    }
    return false
  } catch (e: unknown) {
    console.warn(e)
    return false
  }
}

/**
 * Checks if a vector source is cached in IndexedDB for the given layerId.
 *
 * @param {string} layerId - The unique identifier for the vector source.
 * @returns {Promise<CachedGeoJsonStatus>} A Promise that resolves to one of the following:
 *   - `CachedGeoJsonStatus.cached` if the vector source is found in IndexedDB.
 *   - `CachedGeoJsonStatus.notCached` if the vector source is not found in IndexedDB.
 *   - `CachedGeoJsonStatus.indexDBNotSupported` if the browser does not support IndexedDB.
 */
export async function checkIfVectorSourceIsInIndexedDB (layerId: string): Promise<CachedGeoJsonStatus> {
  if (window.indexedDB !== undefined) {
    const vectorSource = await getVectorSourceFromIndexDB(layerId)
    if (vectorSource !== undefined) {
      return CachedGeoJsonStatus.cached
    } else {
      return CachedGeoJsonStatus.notCached
    }
  } else {
    return CachedGeoJsonStatus.indexDBNotSupported
  }
}

/**
 * Stores a vector source in an IndexedDB object store.
 *
 * @param {string} layerId - The unique identifier for the vector source.
 * @param {Record<string, unknown>} jsonToStore - The JSON data to store in the IndexedDB object store.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` if the vector source is successfully stored,
 * or rejects with an error object if an error occurs during the process.
 */
export async function storeVectorSourceInIndexDB (layerId: string, jsonToStore: Record<string, unknown>): Promise<boolean> {
  return await new Promise((resolve, reject) => {
    const request = indexedDB.open(config.indexDBName, config.schemaVersion)

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const transaction = db.transaction([config.vectorSourceTableName], 'readwrite')
      const objectStore = transaction.objectStore(config.vectorSourceTableName)
      objectStore.put({ layerId, data: jsonToStore })
      resolve(true)
    }

    request.onerror = (e) => {
      reject(e)
    }
  })
}

/**
 * Initializes an IndexedDB database, creates or updates object stores for vector sources and data versions,
 * and handles clearing the database if the data version has changed.
 *
 * @returns {Promise<IndexDBStatus>} A promise that resolves to an `IndexDBStatus` enum value indicating the
 * initialization status. Possible values include:
 * - `IndexDBStatus.databaseInitialized` if the database is successfully initialized or updated.
 * - `IndexDBStatus.vectorSourcesCleared` if the databases vector sources have been cleared.
 * - `IndexDBStatus.failureOccurred` if an error occurred during initialization or clearing the database.
 */
export async function initIndexedDB (): Promise<IndexDBStatus> {
  return await new Promise((resolve, reject) => {
    let databaseStatus: IndexDBStatus
    const dbRequest = indexedDB.open(config.indexDBName, config.schemaVersion)

    // Schema version has changed so we need to check if our object stores are present
    dbRequest.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result

      if (!db.objectStoreNames.contains(config.vectorSourceTableName)) {
        const objectStore = db.createObjectStore(config.vectorSourceTableName, { keyPath: 'layerId' })
        objectStore.createIndex('layerId', 'layerId', { unique: true })
      }

      if (!db.objectStoreNames.contains(config.dataVersionTableName)) {
        const objectStore = db.createObjectStore(config.dataVersionTableName, { keyPath: 'dataVersion' })
        objectStore.createIndex('dataVersion', 'dataVersion', { unique: true })
      }
      databaseStatus = IndexDBStatus.databaseInitialized
    }

    const dbCleared = clearIndexDBIfVersionHasBeenUpdated()
    dbCleared.then((cleared: IndexDBStatus) => {
      resolve(cleared)
    })
      .catch((cleared: boolean) => {
        databaseStatus = IndexDBStatus.failureOccured
        resolve(databaseStatus)
      })

    dbRequest.onerror = (event) => {
      databaseStatus = IndexDBStatus.failureOccured
      resolve(databaseStatus)
    }
  })
}

/**
 * Clears or updates the IndexedDB if the configs dataVersion has changed.
 * If the dataVersion object store is empty it's the first time the database is created so it just updates the data version stored.
 * If the config dataVersion matches the stored data version, the function does nothing.
 * If the data version indicates a change, it clears the vector source object store and updates the dataVersion stored.
 *
 * @returns {Promise<IndexDBStatus>} A promise that resolves to an `IndexDBStatus` enum value indicating the
 * result of the operation. Possible values include:
 * - `IndexDBStatus.databaseInitialized` if the database is successfully initialized or updated.
 * - `IndexDBStatus.vectorSourcesCleared` if the vector source object store is cleared due to a data version change.
 * - `IndexDBStatus.failureOccurred` if an error occurs during the process.
 */
async function clearIndexDBIfVersionHasBeenUpdated (): Promise<IndexDBStatus> {
  return await new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(config.indexDBName, config.schemaVersion)

    dbRequest.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const transaction = db.transaction([config.dataVersionTableName], 'readwrite')
      const objectStore = transaction.objectStore(config.dataVersionTableName)

      // Check the amount of versions to check if this is the first time the db has been created

      // Reverse the entries and get the latest one to know if the vectorSource table should be cleared
      const entries = objectStore.openCursor()
      entries.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result
        if (cursor !== null) {
          const latestVersionInDB = cursor.value.dataVersion
          if (latestVersionInDB !== config.dataVersion) {
            const dataVersion = config.dataVersion
            objectStore.clear()
            objectStore.put({ dataVersion, data: dataVersion })
            const vectorSourcesCleared = clearVectorSourceIndexDB()

            vectorSourcesCleared.then((dbWasCleared) => {
              if (dbWasCleared) resolve(IndexDBStatus.vectorSourcesCleared)
            })
              .catch((e: any) => {
                resolve(IndexDBStatus.failureOccured)
              })
          } else {
            resolve(IndexDBStatus.databaseInitialized)
          }
        } else {
          // Cursor was null so there are no entries in the DB
          const dataVersion = config.dataVersion
          objectStore.put({ dataVersion, data: dataVersion })
          resolve(IndexDBStatus.databaseInitialized)
        }
      }

      entries.onerror = (event) => {
        resolve(IndexDBStatus.failureOccured)
      }
    }

    dbRequest.onerror = (event) => {
      resolve(IndexDBStatus.failureOccured)
    }
  })
}

async function clearVectorSourceIndexDB (): Promise<boolean> {
  return await new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(config.indexDBName, config.schemaVersion)

    dbRequest.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const transaction = db.transaction([config.vectorSourceTableName], 'readwrite')
      const objectStore = transaction.objectStore(config.vectorSourceTableName)
      objectStore.clear()
      resolve(true)
    }

    dbRequest.onerror = (e) => {
      reject(e)
    }
  })
}

/**
 * Retrieves vector source data from IndexedDB based on the provided layerId.
 *
 * @param {string} layerId - The unique identifier for the vector source.
 * @returns {Promise<any | undefined>} A Promise that resolves to the retrieved data if found,
 * or `undefined` if the data is not found or if an error occurs.
 */
export async function getVectorSourceFromIndexDB (layerId: string): Promise<any | undefined> {
  return await new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(config.indexDBName, config.schemaVersion)
    dbRequest.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const transaction = db.transaction([config.vectorSourceTableName], 'readonly')
      const objectStore = transaction.objectStore(config.vectorSourceTableName)
      const request = objectStore.get(layerId)

      request.onsuccess = (event) => {
        const data = request.result
        if (data === undefined) {
          resolve(undefined)
        } else {
          resolve(data.data)
        }
      }

      request.onerror = (event) => {
        resolve(undefined)
      }
    }
    dbRequest.onerror = (event) => {
      resolve(undefined)
    }
  })
}
