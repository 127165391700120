/**
 * @file This file contains any functions relating to the selection controls.
 */

/* eslint-disable @typescript-eslint/no-dynamic-delete */

import { domIds, selectionModes } from '../enums/enums'
import { filterSelectedClusters } from '../events/selectionCalculation'
import { removeExtraSelectionsWhenModeIsSingle, restyleFeatureBackToOriginal, selectionDict } from '../events/selectionEvents'
import { config } from '../utils/configExport'
import { createCheckBoxLabel } from './layerControls'

/**
 * This function creates a select mode control box that allows users to choose between
 * single and multi-select modes and appends it to the provided DOM element.
 *
 * @param {HTMLDivElement} domParent - The parent HTML element to which the control box will be appended.
 * @param {Map} map - The map object that the control interacts with.
 */
export function buildSelectModeBox (domParent: HTMLDivElement, map: Map): void {
  const fragment = new DocumentFragment()

  const title = document.createElement('h3')
  const selectModeList = document.createElement('ul')
  title.innerText = config.selectControl.controlText.title

  const drawBoxRadioInput = buildSelectModeRadioInput(selectionModes.drawBox)
  const labeldrawBoxRadioInput = createCheckBoxLabel(config.selectControl.controlText.drawBoxSelectDisplayName, selectionModes.drawBox)

  const multiSelectRadioInput = buildSelectModeRadioInput(selectionModes.multi)
  const labelmultiSelectRadioInput = createCheckBoxLabel(config.selectControl.controlText.multiSelectDisplayName, selectionModes.multi)

  const singleSelectRadioInput = buildSelectModeRadioInput(selectionModes.single)
  const labelsingleSelectRadioInput = createCheckBoxLabel(config.selectControl.controlText.singleSelectDisplayName, selectionModes.single)

  const clearSelectionsButton = document.createElement('button')
  clearSelectionsButton.type = 'button'
  clearSelectionsButton.innerText = config.selectControl.controlText.clearSelectionButtonText
  clearSelectionsButton.title = config.selectControl.controlText.clearSelectionButtonHelpText
  clearSelectionsButton.id = domIds.clearSelections
  clearSelectionsButton.addEventListener('click', () => {
    clearSelectionsMade(map)
    filterSelectedClusters(map)
  })

  const drawBoxListItem = document.createElement('li')
  drawBoxListItem.appendChild(drawBoxRadioInput)
  drawBoxListItem.appendChild(labeldrawBoxRadioInput)
  if (config.selectControl.controlText.drawBoxSelectHelpText !== undefined) {
    drawBoxListItem.title = config.selectControl.controlText.drawBoxSelectHelpText
  }

  const multiSelectListItem = document.createElement('li')
  multiSelectListItem.appendChild(multiSelectRadioInput)
  multiSelectListItem.appendChild(labelmultiSelectRadioInput)
  if (config.selectControl.controlText.multiSelectHelpText !== undefined) {
    multiSelectListItem.title = config.selectControl.controlText.multiSelectHelpText
  }

  const singleSelectListItem = document.createElement('li')
  singleSelectListItem.appendChild(singleSelectRadioInput)
  singleSelectListItem.appendChild(labelsingleSelectRadioInput)
  if (config.selectControl.controlText.singleSelectHelpText !== undefined) {
    singleSelectListItem.title = config.selectControl.controlText.singleSelectHelpText
  }

  const clearSelectionsListItem = document.createElement('li')
  clearSelectionsListItem.appendChild(clearSelectionsButton)

  selectModeList.appendChild(singleSelectListItem)
  selectModeList.appendChild(multiSelectListItem)
  selectModeList.appendChild(drawBoxListItem)
  selectModeList.appendChild(clearSelectionsListItem)

  singleSelectRadioInput.addEventListener('change', () => { removeExtraSelectionsWhenModeIsSingle(map) })

  fragment.appendChild(title)
  fragment.appendChild(selectModeList)

  domParent.appendChild(fragment)
}

/**
 * Builds a radio input element for selecting a specific selection mode.
 *
 * @param {selectionModes} selectMode - The selection mode to associate with the radio input.
 * @returns {HTMLInputElement} The created radio input element.
 */
export function buildSelectModeRadioInput (selectMode: selectionModes): HTMLInputElement {
  const radioInput = document.createElement('input')
  radioInput.type = 'radio'
  radioInput.id = selectMode
  radioInput.value = selectMode
  radioInput.name = 'selectModeControl'
  if (selectMode === config.selectControl.selectionMode) radioInput.checked = true

  return radioInput
}

/**
 * Retrieves the current selection mode based on the radio input elements. If the radio input cannot be found
 * the function will return the selection mode specified in the config file
 *
 * @returns {selectionModes} The current selection mode (single or multi).
 */
export function getCurrentSelectMode (): selectionModes {
  const selectMode = document.querySelector('input[name=selectModeControl]:checked').value
  if (selectMode !== null) return selectMode
  return config.selectControl.selectionMode
}

/**
 * Clears the selections made on a map by clearing the selectionDict and restyling any features back to their original state
 *
 * @param {Map} map - The map object on which selections were made.
 * @returns {void}
 */
export function clearSelectionsMade (map: Map): void {
  const keys = Object.keys(selectionDict)
  // restyle any previously selected items
  for (const featureId of keys) {
    clearSelectedFeature(map, featureId)
  }
}

/**
 * Restyles the selected feature on a map and removes it from the selection dictionary.
 *
 * @param {Map} map - The map object on which the feature is displayed.
 * @param {string} featureId - The unique identifier of the feature to clear.
 * @returns {void}
 */
export function clearSelectedFeature (map: Map, featureId: string): void {
  restyleFeatureBackToOriginal(featureId, map)
  delete selectionDict[featureId]
}
