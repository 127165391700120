/**
 * @file Contains all enums.
 */

/*
 * Defines references to controls by role. Extendable.
 */
export enum controlRole {
  advanced = 'advanced',
  layers = 'layers',
  select = 'select',
  years = 'years',
}

/*
 * Defines all reference-able DOM IDs. Extendable.
 */
export enum domIds {
  advancedControl = 'advancedControl',
  layersControl = 'layersControl',
  map = 'map',
  selectControl = 'selectControl',
  clearSelections = 'clearSelections',
  guidance = 'guidance',
  warning = 'warning',

  // Ids within year control
  yearsControl = 'yearsControl',
  leftButton = 'previousYear',
  rightButton = 'nextYear',
  currentYearDisplay = 'yearDisplay',
  currentYearDataValueId = 'data-current-year',

  // Ids within downloads section
  download = 'download',
  downloadClusterButton = 'downloadClusterButton',

  // Ids within result section
  result = 'result',
  resultDetailElement = 'resultDetailElement',

  basicResultDataContainer = 'basicResultDataContainer',
  basicResultDataTable = 'basicResultDataTable',
  basicResultDataTableHeader = 'basicResultDataTableHeader',
  basicResultDataTableBody = 'basicResultDataTableBody',

  clusterBreakdownModeTitle = 'clusterBreakdownModeTitle',
  clusterBreakdownModeDescription = 'clusterBreakdownModeDescription',

  clusterBreakdownContainer = 'clusterBreakdownContainer',
  clusterBreakdownTable = 'clusterBreakdownTable',
  clusterBreakdownTableHeader = 'clusterBreakdownTableHeader',
  clusterBreakdownTableBody = 'clusterBreakdownTableBody',

  proximityResultDetailElement = 'proximityResultDetailElement',
  proximityResultTable = 'proximityResultTable',
  proximityResultTableHeader = 'proximityResultTableHeader',
  proximityResultTableBody = 'proximityResultTableBody',
  proximityLoadingSpinner = 'proximityLoadingSpinner'
}

/*
 * Defines all reference-able slider IDs. The keys must correspond with
 * the sliderIDs found within config.
 */
export enum sliderIds {
  certainty = 'certainty',
  range = 'range'
}

/*
 * Defines feature properties the code uses to display tooltips and get layer specific data
 *
 */
export enum featurePropertyParameters {
  layerId = 'layerId',
  hydrogenDemand = 'hydrogenDemand',
  cleanedCertaintyPercentage = 'cleanedCertaintyPercentage',
  rawCertaintyPercentage = 'rawCertaintyPercentage'
}

/*
 * Defines the possible selection modes for the application
 */
export enum selectionModes {
  single = 'singleSelectionMode',
  multi = 'multiSelectionMode',
  drawBox = 'drawBoxSelectionMode'
}

/*
 * Defines all statuses of geoJSON's stored within local storage.
 */
export enum CachedGeoJsonStatus {
  cached = 1,
  notCached = 2,
  indexDBNotSupported = 3,
}

/*
 * Defines all statuses of the IndexedDB upon initialization.
 */
export enum IndexDBStatus {
  vectorSourcesCleared = 1,
  databaseInitialized = 2,
  failureOccured = 3
}

/*
 * Defines all the types of features within the application.
 */
export enum FeatureType {
  cluster = 'cluster',
  boundary = 'boundary',
  lineString = 'lineString',
  point = 'point'
}

/*
 * Defines all keys used within the resultData dict
 */
export enum BreakdownDataKeys {
  hydrogenDemand = 'totalHydrogenDemand',
  certainty = 'certainty',
  certaintyCount = 'count',
}

/*
 * Defines the type of key within the config file.
 * Currently we only have 1 type a criticalConfigKey
 * which is a config parameter that must be populated and cannot be defaulted
 */
export enum configKeyType {
  criticalConfigKey = 'Critical Config Key'
}

/*
 * Defines all coordinate systems used within the application
 */
export enum CoordinateSystems {
  OpenLayersDefault = 'EPSG:3857',
  TurfJSDefault = 'EPSG:4326'
}

/*
 * Defines all possible WFS types used within the application
 */
export enum WFSSourceType {
  ONS = 'ONS'
}
