/**
 * @file This file contains any functions relating to the layer events.
 */

import type VectorLayer from 'ol/layer/Vector'

import { getActiveVectorLayerFromVectorLayerId, getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import { addVectorSourceToVectorSourceDict, initSingleVectorSourceFromDict } from '../processing/vectorSources'
import { displayError } from '../utils/alert'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { removeSelectionsThatAreInvisible, removeSpecificLayersSelectedFeatures, styleAllSelectionsWithinSelectionDict } from './selectionEvents'
import { config } from '../utils/configExport'
import { filterSelectedClusters } from './selectionCalculation'

/**
 * Toggles the opacity of a given VectorLayer.
 *
 * @param {VectorLayer} vectorLayer - The VectorLayer whose opacity will be toggled.
 * @returns {void}
 */
export function switchOpacityOfVectorLayer (vectorLayer: VectorLayer): void {
  const currentOpacity: number = vectorLayer.getOpacity()

  switch (currentOpacity) {
    case 0:
      vectorLayer.setOpacity(1)
      break
    default:
      vectorLayer.setOpacity(0)
      break
  }
}

/**
 * Toggles the opacity of a vector layer associated with a checkbox control and triggers a map render. If the layer is
 * not present in the vectorSourceDict it will add the source into the dict and add the layer to the map.
 *
 * While the source is being loaded the layer button is disabled to avoid unwanted behavior
 *
 * @param {string} layerId - The ID of the vector layer to control.
 * @param {Map} map - The map instance on which the layer is rendered.
 * @returns {void}
 */
export function layerCheckboxControlFunction (layerId: string, map: Map, vectorSourceDict: Record<string, VectorSource>): void {
  const layerCheckbox = document.getElementById(`${config.controlIDPrefix}${layerId}`)
  if (layerCheckbox !== null) {
    layerCheckbox.disabled = true
    layerCheckbox.style.cursor = 'wait'
    const vectorLayer = getActiveVectorLayerFromVectorLayerId(layerId, map)
    if (vectorLayer === null) {
      // The layer has not been loaded yet so we should add it to our vector source dict
      vectorSourceDict = addVectorSourceToVectorSourceDict(vectorSourceDict, layerId)
      vectorSourceDict
        .then((vectorSourceDict: Record<string, VectorSource>) => {
          initSingleVectorSourceFromDict(vectorSourceDict, layerId, map)
          layerCheckbox.disabled = false
          layerCheckbox.style.cursor = 'auto'
          map.render()
        })
        .catch((e: any) => {
          const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
          displayError(`An error occured loading the layer : ${layer.displayName} please refresh and try again`)
        })
      removeSelectionsThatAreInvisible()
      styleAllSelectionsWithinSelectionDict(map)
      filterSelectedClusters(map)
    } else {
      removeSpecificLayersSelectedFeatures(layerId, map)
      switchOpacityOfVectorLayer(vectorLayer)
      filterSelectedClusters(map)
      layerCheckbox.disabled = false
      layerCheckbox.style.cursor = 'auto'
      map.render()
    }
  }
}

/**
 * This function checks the state of layer checkboxes to determine which layers are currently visible and returns an array
 * of layer IDs.
 *
 * @returns {string[]} An array of layer IDs that are currently visible.
 */
export function getLayerIdsThatAreVisible (): string[] {
  const layers = config.layers
  const visibleLayers: string[] = []
  for (const layerId in layers) {
    const layerCheckboxId = `${config.controlIDPrefix}${layerId}`
    const layersCheckboxElement: HTMLInputElement = document.getElementById(layerCheckboxId)
    if (layersCheckboxElement !== null && layersCheckboxElement.checked) {
      visibleLayers.push(layerId)
    }
  }
  return visibleLayers
}
