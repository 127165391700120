/**
 * @file This file contains any functions relating to the layer selection controls.
 */

import { layerCheckboxControlFunction } from '../events/layerEvents'
import { checkIfConfigIndexHasValidLayerKeys } from '../utils/utils'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { displayError } from '../utils/alert'
import { config } from '../utils/configExport'

/**
 * Builds and appends a layer selection section to the specified DOM parent element.
 *
 * This function creates a section titled "Layers" and appends it to the provided DOM parent element.
 * Within the "Layers" section, it generates checkboxes for each layer based on the ordering from the config.
 *
 * @param {HTMLDivElement} domParent - The HTML div element to which the layer selection section will be appended.
 * @param {Map} map - The Map object that the controls interact with.
 * @returns {void}
 */
export function buildLayerSelectbox (domParent: HTMLDivElement, map: Map, vectorSourceDict: Record<string, VectorSource>): void {
  const fragment = new DocumentFragment()

  const title = document.createElement('h3')
  title.innerText = config.layerControl.controlText.title
  // Get the legend index and check for valid keys
  const layersOrdering = config.legendIndex
  const orderingIsValid = checkIfConfigIndexHasValidLayerKeys(layersOrdering)
  const layers = config.layers
  const layerList = document.createElement('ul')
  if (config.layerControl.controlText.helpText !== undefined) {
    layerList.title = config.layerControl.controlText.helpText
    title.title = config.layerControl.controlText.helpText
  }

  if (orderingIsValid) {
    for (const layerId of layersOrdering) {
      const layer: ConfigDefinedLayer = layers[layerId]
      const checkBoxContainer = document.createElement('div')
      checkBoxContainer.className = 'controlFrame'
      const layerCheckboxId = `${config.controlIDPrefix}${layerId}`
      const checkBox = createLayerCheckBoxEntry(layer, layerCheckboxId)
      const label = createCheckBoxLabel(layer.displayName, layerCheckboxId)

      const legendSpan = document.createElement('span')
      const legendClass = `legend-${layer.legendSpanType}`
      legendSpan.className = legendClass
      legendSpan.style.color = layer.primaryColor

      checkBox.addEventListener('change', function () { layerCheckboxControlFunction(layerId, map, vectorSourceDict) })

      checkBoxContainer.appendChild(checkBox)
      checkBoxContainer.appendChild(legendSpan)
      checkBoxContainer.appendChild(label)

      const listItem = document.createElement('li')
      listItem.appendChild(checkBoxContainer)
      if (layer.helpText !== undefined) {
        listItem.title = layer.helpText
      }
      layerList.appendChild(listItem)
    }
    fragment.appendChild(title)
    fragment.appendChild(layerList)
    domParent.appendChild(fragment)
  } else {
    displayError('The config value of legendIndex doesnt have valid keys please check the provided values')
  }
}

/**
 * Creates a checkbox input element for a layer with the specified properties.
 *
 * @param {ConfigDefinedLayer} layer - The layer object containing information about the layer.
 * @param {string} htmlFor - The value for the 'for' attribute of the associated label.
 * @returns {HTMLInputElement} A checkbox input element representing the layer.
 */
function createLayerCheckBoxEntry (layer: ConfigDefinedLayer, htmlFor: string): HTMLInputElement {
  const checkBox = document.createElement('input')
  checkBox.type = 'checkbox'
  checkBox.id = htmlFor
  checkBox.name = layer.displayName
  checkBox.value = htmlFor
  checkBox.checked = layer.isVisible
  return checkBox
}

/**
 * Creates a label element for a checkbox input with the specified properties.
 *
 * @param {string} textContent - The text content to be displayed by the label.
 * @param {string} htmlFor - The value for the 'for' attribute, associating the label with a checkbox.
 * @returns {HTMLLabelElement} A label element associated with a checkbox input.
 */
export function createCheckBoxLabel (textContent: string, htmlFor: string): HTMLLabelElement {
  const labelForCheckBox = document.createElement('label')
  labelForCheckBox.htmlFor = htmlFor
  labelForCheckBox.textContent = textContent
  return labelForCheckBox
}
