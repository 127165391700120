/**
 * @file This file contains any functions relating to the year selection controls.
 */

import { domIds } from '../enums/enums'
import { updateLayersFromYearChange } from '../events/yearEvents'
import { config } from '../utils/configExport'

/**
 * Builds a year slider interface within a specified DOM parent element.
 *
 * @param {HTMLDivElement} domParent - The HTML container where the year slider will be appended.
 * @param {Object} specification - The specification object containing information about years and current year.
 * @param {Map} map - The Map object that the controls interact with.
 * @returns {void}
 */
export function buildYearSlider (domParent: HTMLDivElement, specification: any, map: Map): void {
  const yearSliderContainer = document.createElement('div')
  yearSliderContainer.className = 'controlFrame'

  const title = document.createElement('h3')
  title.innerText = config.yearControl.controlText.title

  // Create slider buttons
  const prevYear = document.createElement('button')
  prevYear.type = 'button'
  prevYear.innerText = config.yearControl.controlText.leftButtonText
  prevYear.id = domIds.leftButton
  if (config.yearControl.controlText.leftButtonHelpText !== undefined) {
    prevYear.title = config.yearControl.controlText.leftButtonHelpText
  }

  const nextYear = document.createElement('button')
  nextYear.type = 'button'
  nextYear.innerText = config.yearControl.controlText.rightButtonText
  nextYear.id = domIds.rightButton
  if (config.yearControl.controlText.rightButtonHelpText !== undefined) {
    nextYear.title = config.yearControl.controlText.rightButtonHelpText
  }

  // Disable buttons where default year at start or end of sequence
  const allYears = Object.keys(config.years)
  if (allYears.length > 0) {
    if (allYears[0] === String(config.defaultYear)) prevYear.disabled = true
    if (allYears[allYears.length - 1] === String(config.defaultYear)) nextYear.disabled = true
  }

  // Display current year
  const yearDisplay = document.createElement('span')
  yearDisplay.innerText = config.years[config.defaultYear].displayName
  yearDisplay.id = domIds.currentYearDisplay
  yearDisplay.setAttribute(domIds.currentYearDataValueId, config.defaultYear)

  if (config.yearControl.controlText.yearDisplayHelpText !== undefined) {
    title.title = config.yearControl.controlText.yearDisplayHelpText
    yearDisplay.title = config.yearControl.controlText.yearDisplayHelpText
  }

  // Add Event logic here
  prevYear.addEventListener('click', () => {
    changeYearDisplayed(-1, yearDisplay, prevYear, nextYear, specification)
    updateLayersFromYearChange(map)
  })

  nextYear.addEventListener('click', () => {
    changeYearDisplayed(1, yearDisplay, prevYear, nextYear, specification)
    updateLayersFromYearChange(map)
  })
  yearSliderContainer.appendChild(prevYear)
  yearSliderContainer.appendChild(yearDisplay)
  yearSliderContainer.appendChild(nextYear)
  domParent.appendChild(title)
  domParent.appendChild(yearSliderContainer)
}

/**
 * Change the displayed year and update button states based on an index. It stores the current year under the attribute defined from config
 *
 * @param {number} index - The index to move the displayed year.
 * @param {HTMLParagraphElement} textDisplay - The HTML paragraph element where the year text is displayed.
 * @param {HTMLButtonElement} leftButton - The left navigation button.
 * @param {HTMLButtonElement} rightButton - The right navigation button.
 * @param {Object} specification - An object containing year-related information.
 *
 * @returns {void}
 */
function changeYearDisplayed (index: number, textDisplay: HTMLParagraphElement, leftButton: HTMLButtonElement, rightButton: HTMLButtonElement, specification: any): void {
  const currentYear = getCurrentYearDisplayed()
  const allYears = Object.keys(config.years)
  let currentIndex = allYears.indexOf(currentYear)
  currentIndex += index

  switch (currentIndex) {
    case allYears.length - 1:
      rightButton.disabled = true
      break

    case 0:
      leftButton.disabled = true
      break

    default:
      leftButton.disabled = false
      rightButton.disabled = false
      break
  }

  textDisplay.innerText = config.years[allYears[currentIndex]].displayName
  textDisplay.setAttribute(domIds.currentYearDataValueId, allYears[currentIndex])
}

/**
 * Retrieves the current year displayed on the webpage, or returns the default year
 * if the element is not found or the year attribute is not set.
 *
 * @returns {string} The current year as a string, or the default year if unavailable.
 */
export function getCurrentYearDisplayed (): string {
  const yearDisplay = document.getElementById(domIds.currentYearDisplay)
  if (yearDisplay === null) {
    // We know browser rendering has failed as yearDisplay is null so therefore the year cannot have changed from the default
    return config.defaultYear
  }
  const currentYear = yearDisplay.getAttribute(domIds.currentYearDataValueId)
  if (currentYear === null) {
    // We know browser rendering has failed as yearDisplay attribute is null so therefore the year cannot have changed from the default
    return config.defaultYear
  }
  return currentYear
}
