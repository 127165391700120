/**
 * @file This file contains any functions relating to the selection events.
 */

/* eslint-disable @typescript-eslint/no-dynamic-delete */

import type { Feature, Map } from 'ol'
import type { Style } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import DragBox from 'ol/interaction/DragBox'

import { getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import { FeatureType, featurePropertyParameters, selectionModes } from '../enums/enums'
import { config } from '../utils/configExport'
import { clearSelectedFeature, clearSelectionsMade, getCurrentSelectMode } from '../controls/selectControls'
import { getLayerIdsThatAreVisible } from './layerEvents'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { createDefaultClusterStyle, createDefaultNoFillFeatureStyle, createPointStyle, createSelectedClusterStyle, createSelectedFeatureStyle } from './featureStyling'
import { filterSelectedClusters } from './selectionCalculation'
import { cleanCertaintyValue } from '../processing/renderingCalculations'

export const selectionDict: Record<string, Feature> = {}

/**
 * Handles selection logic based on the clicked pixel and the current selection mode.
 *
 * This function retrieves features from vector layers at the clicked pixel and applies
 * selection logic based on the current selection mode (single or multi-select).
 *
 * @param {number[]} pixel - The pixel coordinates [x, y] where the user clicked.
 * @param {Map} map - The map object to interact with.
 */
export async function selectionLogic (pixel: [number, number], map: Map): Promise<void> {
  const visibleLayers: string[] = getLayerIdsThatAreVisible()
  const selectionMode: selectionModes = getCurrentSelectMode()
  await getFirstFeatureFromPixelClick(pixel, visibleLayers, map)
    .then((feature: Feature | undefined) => {
      if (feature !== undefined) {
        if (selectionMode === selectionModes.multi) multiSelectionLogic(feature, selectionDict, map)
        if (selectionMode === selectionModes.single) singleSelectionLogic(feature, selectionDict, map)
        filterSelectedClusters(map)
      }
    })
}

/**
 * Asynchronously retrieves the first feature from a map's vector layers based on a pixel click.
 *
 * @param {Array<number>} pixel - The pixel coordinates [x, y] representing the click location.
 * @param {Map} map - The map containing the vector layers.
 *
 * @returns {Promise<Feature | undefined>} A Promise that resolves to the first Feature found at the clicked pixel,
 * or undefined if no features are found in any of the layers.
 */
async function getFirstFeatureFromPixelClick (pixel: [number, number], visibleLayers: string[], map: Map): Promise<Feature | undefined> {
  const feature = map.forEachFeatureAtPixel(pixel, (feature) => {
    const featuresLayerId = feature.get(featurePropertyParameters.layerId)
    if (visibleLayers.includes(featuresLayerId)) return feature
  }, { hitTolerance: config.selectControl.selectionHitTolerance })
  return feature
}

/**
 * Handles multi-selection logic for a feature.
 *
 * This function manages the selection and deselection of a feature in multi-select mode
 * (allows user to select more than one feature).
 *
 * @param {Feature} feature - The feature to be selected or deselected.
 * @param {Record<string, Feature>} selectionDict - A dictionary containing selected features.
 * @param {Map} map - The map object to interact with.
 */
function multiSelectionLogic (feature: Feature, selectionDict: Record<string, Feature>, map: Map): void {
  const featureId = feature.getId()
  const existingSelection = selectionDict[featureId]

  if (existingSelection === undefined) {
    selectionDict[featureId] = feature
    styleSelectedFeatureFromSavedFeatureProperties(feature, map, config.selectControl.selectionColor)
  } else {
    clearSelectedFeature(map, featureId)
  }
}

/**
 * Handles single-selection logic for a feature.
 *
 * This function manages the selection and deselection of a feature in single-select mode.
 *
 * @param {Feature} feature - The feature to be selected or deselected.
 * @param {Record<string, Feature>} selectionDict - A dictionary containing selected features.
 * @param {Map} map - The map object to interact with.
 */
function singleSelectionLogic (feature: Feature, selectionDict: Record<string, Feature>, map: Map): void {
  const featureId = feature.getId()
  const existingSelection = selectionDict[featureId]

  if (existingSelection === undefined) {
    if (Object.keys(selectionDict).length === 0) {
      // Selection dict is empty
      selectionDict[featureId] = feature
      styleSelectedFeatureFromSavedFeatureProperties(feature, map, config.selectControl.selectionColor)
    } else {
      clearSelectionsMade(map)
      selectionDict[featureId] = feature
      styleSelectedFeatureFromSavedFeatureProperties(feature, map, config.selectControl.selectionColor)
    }
  } else {
    clearSelectedFeature(map, featureId)
  }
}

/**
 * Restyles a previously selected feature back to its original styling.
 *
 * This function takes a feature ID and a map object, retrieves the feature with the ID
 * from the selection dictionary, and restyles it to its original styling using information
 * from the configuration.
 *
 * @param {string} featureId - The ID of the feature to be restyled.
 * @param {Map} map - The map object to interact with.
 */
export function restyleFeatureBackToOriginal (featureId: string, map: Map): void {
  const feature = selectionDict[featureId]
  if (feature !== null) {
    const layerId = feature.get(featurePropertyParameters.layerId)
    const layer = getLayerFromConfigWithLayerId(layerId)
    styleSelectedFeatureFromSavedFeatureProperties(feature, map, layer.primaryColor)
  }
}

/**
 * Styles a selected feature based on saved feature properties.
 *
 * This function styles a selected feature using specific properties, such as hydrogen demand,
 * certainty percentage. It updates the feature's style accordingly.
 *
 * @param {Feature} feature - The feature to be styled.
 * @param {Map} map - The map object for rendering the feature.
 * @param {string} fillColor - The color to use for styling.
 */
function styleSelectedFeatureFromSavedFeatureProperties (feature: Feature, map: Map, fillColor: string): void {
  const layerId = feature.get(featurePropertyParameters.layerId)
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  if (layer.featureType === FeatureType.cluster) {
    styleSelectedCluster(feature, fillColor)
  } else if (layer.featureType === FeatureType.point) {
    const selectedStyle: Style = createPointStyle(layerId, fillColor)
    feature.setStyle(selectedStyle)
  } else {
    if (fillColor === config.selectControl.selectionColor) {
      const selectedStyle: Style = createSelectedFeatureStyle(layerId)
      feature.setStyle(selectedStyle)
    } else {
      const defaultStyle: Style = createDefaultNoFillFeatureStyle(layerId)
      feature.setStyle(defaultStyle)
    }
  }
}

/**
 * Removes selected features from a specific layer based on the layer ID.
 *
 * This function iterates through the selected features in the `selectionDict` and removes
 * those belonging to the specified layer ID by restoring their original styling.
 *
 * @param {string} layerId - The ID of the layer from which selected features should be removed.
 * @param {Map} map - The map object to interact with.
 */
export function removeSpecificLayersSelectedFeatures (layerId: string, map: Map): void {
  for (const featureId in selectionDict) {
    const feature: Feature = selectionDict[featureId]
    if (feature !== null) {
      const featuresLayerId = feature.get(featurePropertyParameters.layerId)
      if (layerId === featuresLayerId) {
        clearSelectedFeature(map, featureId)
      }
    }
  }
}

/**
 * Removes extra selections when the selection mode is set to single.
 *
 * This function is used to remove all but the last selected feature when the selection mode is
 * set to single-select. It restores the original styling of removed features.
 *
 * @param {Map} map - The map object to interact with.
 */
export function removeExtraSelectionsWhenModeIsSingle (map: Map): void {
  // Remove the last entry to keep into the selection dict
  const keys = Object.keys(selectionDict)
  keys.pop()

  // Remove the rest of the entries
  for (const featureId of keys) {
    clearSelectedFeature(map, featureId)
  }
}

/**
 * Removes selections that correspond to invisible or hidden features on the map.
 *
 * This function iterates through the selected features in the `selectionDict` and removes
 * those that are either in invisible layers or represent hidden clusters with a radius of 0.
 *
 */
export function removeSelectionsThatAreInvisible (): void {
  const visibleLayers = getLayerIdsThatAreVisible()
  for (const featureId in selectionDict) {
    const feature: Feature = selectionDict[featureId]
    const featuresLayerId = feature.get(featurePropertyParameters.layerId)
    if (visibleLayers.includes(featuresLayerId)) {
      // The layer is visible but we need to check if the individual clusters are visible
      const hydrogenDemand = feature.get(featurePropertyParameters.hydrogenDemand)
      if (hydrogenDemand === 0) {
        // The cluster will not be visible on the map if hydrogenDemand is 0 as the radius of the cluster will be 0
        delete selectionDict[featureId]
      }
    } else {
      // Layer is not visible so selection should not be present
      delete selectionDict[featureId]
    }
  }
}

/**
 * Styles all selected features within the selection dictionary with a specific color.
 *
 * This function iterates through the selected features in the `selectionDict` and applies a selection
 * styling to each feature.
 *
 * @param {Map} map - The map object to interact with.
 */
export function styleAllSelectionsWithinSelectionDict (map: Map): void {
  for (const featureId in selectionDict) {
    const feature: Feature = selectionDict[featureId]
    styleSelectedFeatureFromSavedFeatureProperties(feature, map, config.selectControl.selectionColor)
  }
}

/**
 * Applies a cluster style to a feature on a map, based on its hydrogen demand and certainty percentage.
 *
 * @param {Feature} feature - The feature to apply the style to.
 * @param {string} color - The color to use for styling the feature.
 *
 * @returns {void}
 */
function styleSelectedCluster (feature: Feature, color: string): void {
  const hydrogenDemand: number = feature.get(featurePropertyParameters.hydrogenDemand)
  const certaintyPercentage = feature.get(featurePropertyParameters.rawCertaintyPercentage)
  const cleanedCertaintyValue = cleanCertaintyValue(certaintyPercentage)
  const layerId: string = feature.get(featurePropertyParameters.layerId)

  if (color === config.selectControl.selectionColor) {
    const selectedStyle: Style = createSelectedClusterStyle(hydrogenDemand, cleanedCertaintyValue)
    feature.setStyle(selectedStyle)
  } else {
    const defaultStyle: Style = createDefaultClusterStyle(hydrogenDemand, cleanedCertaintyValue, layerId)
    feature.setStyle(defaultStyle)
  }
}

/**
 * Sets up a drag box selection interaction on the provided OpenLayers map.
 *
 * @param {Map} map - The OpenLayers map on which to set up the drag box selection.
 * @returns {void}
 */
export function setupDragBoxSelection (map: Map): void {
  const dragBox = new DragBox({
    condition: function (event) {
      const selectionMode: selectionModes = getCurrentSelectMode()
      return selectionMode === selectionModes.drawBox
    }
  })

  dragBox.on('boxend', function () {
    const extent = dragBox.getGeometry().getExtent()
    const selectionMode: selectionModes = getCurrentSelectMode()
    const visibleLayers: string[] = getLayerIdsThatAreVisible()
    map.getLayers().forEach(function (layer) {
      if (layer instanceof VectorLayer && visibleLayers.includes(layer.getProperties().name)) {
        layer.getSource().forEachFeatureIntersectingExtent(extent, function (feature: Feature) {
          if (selectionMode === selectionModes.drawBox) multiSelectionLogic(feature, selectionDict, map)
        })
      }
    })
    filterSelectedClusters(map)
  })

  map.addInteraction(dragBox)
}
