/**
 * @file This file contains any functions relating to the creation of the proximity table.
 */

import type { Map } from 'ol'

import { config } from '../../utils/configExport'
import { domIds } from '../../enums/enums'
import { createBaseResultTable, createTextContentForResultTable } from '../results'
import { calculateProximityData } from '../../events/resultDataCalculation'
import { createColumnAndHeaderList, createProximityLayerList } from '../../utils/utils'
import { getLayerFromConfigWithLayerId } from '../../processing/vectorLayers'
import { getLayerIdsThatAreVisible } from '../../events/layerEvents'
import type { TableConfigObject } from '../../structures/interfaces'

export function createProximityResultSection (map: Map): HTMLDetailsElement {
  const proximitySummaryElement = document.createElement('summary')
  proximitySummaryElement.textContent = config.resultsConfiguration.proximityResultSection.title

  const proximityDetailElement = document.createElement('details')
  proximityDetailElement.id = domIds.proximityResultDetailElement
  proximityDetailElement.appendChild(proximitySummaryElement)
  const proximityCalculationSubtitle = document.createElement('p')
  proximityCalculationSubtitle.textContent = config.resultsConfiguration.proximityResultSection.subtitle

  const proximityTableContainer = createBaseResultTable(domIds.proximityResultTable, domIds.proximityResultTableHeader, domIds.proximityResultTableBody)
  const spinnerContainer = createLoadingSpinner()

  proximityTableContainer.appendChild(spinnerContainer)
  proximityDetailElement.appendChild(proximityCalculationSubtitle)
  proximityDetailElement.appendChild(proximityTableContainer)

  proximitySummaryElement.addEventListener('click', () => {
    const loadingSpinner = document.getElementById(domIds.proximityLoadingSpinner)
    if (loadingSpinner !== null) {
      loadingSpinner.style.display = 'block'
      clearProximityTable()
      setTimeout(() => {
        buildProximityTable(map)
        loadingSpinner.style.display = 'none'
      }, 1000)
    }
  })

  return proximityDetailElement
}

/**
 * Builds and populates the proximity result table based on the provided map and configuration.
 *
 * @param {Map} map - The map object used for proximity calculations.
 *
 * @returns {void}
 */
export function buildProximityTable (map: Map): void {
  const [tableheadersColumns, tableheaders] = createColumnAndHeaderList(config.resultsConfiguration.proximityResultSection)

  const htmlTable = document.getElementById(domIds.proximityResultTable)
  const htmlTableHeader = document.getElementById(domIds.proximityResultTableHeader)
  const htmlTableBody = document.getElementById(domIds.proximityResultTableBody)
  const proximityData = calculateProximityData(map)

  // Clear whatever is existing within the table currently
  clearProximityTable()

  // Check that the result section has rendered correctly before trying to display results
  if (htmlTable !== null && htmlTableHeader !== null && htmlTableBody !== null && Object.keys(proximityData).length > 0) {
    const tableHeaderRow = document.createElement('tr')
    tableheaders.forEach((displayName: string) => {
      const headerEntry = document.createElement('th')
      headerEntry.textContent = displayName
      tableHeaderRow.appendChild(headerEntry)
    })
    htmlTableHeader.appendChild(tableHeaderRow)
    htmlTable.appendChild(htmlTableHeader)

    config.legendIndex.forEach((layerId: string) => { // Display in legend order
      if (layerId in proximityData) {
        const layerConfig = getLayerFromConfigWithLayerId(layerId)
        const layersProximityData = proximityData[layerId]
        const tableRow = document.createElement('tr')
        const cell = document.createElement('td')
        cell.textContent = layerConfig.displayName
        tableRow.appendChild(cell)
        tableheadersColumns.forEach((column: string) => {
          const entriesConfig: TableConfigObject = config.resultsConfiguration.proximityResultSection.tableConfig[column]
          const textContent = createTextContentForResultTable(entriesConfig, layersProximityData[column])
          const cell = document.createElement('td')
          cell.textContent = textContent
          tableRow.appendChild(cell)
        })
        htmlTableBody.appendChild(tableRow)
      }
    })

    htmlTable.appendChild(htmlTableBody)
  }
}

/**
 * Creates a loading spinner container with a spinner element.
 *
 * @returns {HTMLDivElement} - The container div element containing the loading spinner.
 */
export function createLoadingSpinner (): HTMLDivElement {
  const spinnerContainer = document.createElement('div')
  const proximityLoadingSpinner = document.createElement('div')
  proximityLoadingSpinner.className = 'loader'
  proximityLoadingSpinner.id = domIds.proximityLoadingSpinner
  proximityLoadingSpinner.style.display = 'none'
  spinnerContainer.appendChild(proximityLoadingSpinner)
  return spinnerContainer
}

/**
 * Clears the content of the header and body of the proximity result table.
 *
 * @returns {void}
 */
export function clearProximityTable (): void {
  const htmlTableHeader = document.getElementById(domIds.proximityResultTableHeader)
  const htmlTableBody = document.getElementById(domIds.proximityResultTableBody)
  if (htmlTableHeader !== null && htmlTableBody !== null) {
    htmlTableHeader.innerHTML = ''
    htmlTableBody.innerHTML = ''
  }
}

/**
 * Checks whether the proximity calculation section should be visible based on if any proximity layers are active.
 *
 * @returns {void}
 */
export function checkIfProximityCalculationShouldBeVisible (): void {
  const proximityDetailElement = document.getElementById(domIds.proximityResultDetailElement)
  const activeLayers = getLayerIdsThatAreVisible()
  const proximityLayers = createProximityLayerList()
  let proximityCalculationToBeShown = false
  if (proximityDetailElement !== null) {
    for (const activeLayerId of activeLayers) {
      if (proximityLayers.includes(activeLayerId)) proximityCalculationToBeShown = true
    }

    if (proximityCalculationToBeShown) {
      // Show the detail element and close it as if its already shown it has old data
      proximityDetailElement.style.display = 'block'
      proximityDetailElement.open = false
    } else {
      proximityDetailElement.style.display = 'none'
    }
  }
}
