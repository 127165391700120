/* eslint-disable @typescript-eslint/no-unused-vars */

import './css/style.css'
import type VectorSource from 'ol/source/Vector'

import { createVectorSourceDict, initVectorSourcesWithinDict } from './processing/vectorSources'
import { createBaseMap } from './processing/map'
import { selectionLogic, setupDragBoxSelection } from './events/selectionEvents'
import { updateClustersOnMap } from './events/sliderEvents'
import { buildGuidance, buildWarning } from './construction/guidance'
import { initIndexedDB } from './processing/localStorage'
import { IndexDBStatus } from './enums/enums'
import { displayError } from './utils/alert'
import { buildResultsSection } from './construction/results'
import { buildDownloadSection } from './construction/download'

const databaseInitialized = initIndexedDB()
const vectorSourceDict: Record<string, VectorSource> = {}

databaseInitialized.then((value: IndexDBStatus) => {
  if (value === IndexDBStatus.databaseInitialized || value === IndexDBStatus.vectorSourcesCleared) {
    const initializedVectorSourceDict = createVectorSourceDict(vectorSourceDict)

    initializedVectorSourceDict
      .then((vectorSourceDict: Record<string, VectorSource>) => {
        const map = createBaseMap(vectorSourceDict)
        setupDragBoxSelection(map)
        initVectorSourcesWithinDict(vectorSourceDict, map)
        // Update cluster layer with styling
        updateClustersOnMap(map)
        buildGuidance()
        buildDownloadSection(map)
        buildWarning()
        buildResultsSection(map)

        map.on('click', function (evt) {
          void selectionLogic(evt.pixel, map)
        })
      })
      .catch((e: any) => {
        // Leave this for debugging purposes?
        console.warn(e)
      })
  } else {
    // Failure occured on initializing of db
    displayError('The loading of cached vectorSources has failed please refresh')
  }
})
  .catch((e) => {
    console.warn(e)
  })

export default vectorSourceDict
