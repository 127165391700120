/**
 * @file This file contains functions that construct the downloads section.
 *
 */

import { config } from '../utils/configExport'
import { domIds } from '../enums/enums'
import { clearSelectionsMade } from '../controls/selectControls'
import { filterSelectedClusters } from '../events/selectionCalculation'
import { convertSelectionDictIntoGeoJson, getClusterDataFromClustersToCalculate } from '../events/downloadEvents'
import { downloadJson, turnArrayOfJsonToCSVDownload } from '../utils/utils'
import { selectionDict } from '../events/selectionEvents'
import { resultData } from '../events/resultDataCalculation'

/**
 * Populates downloads section and then hides it as there are no selections
 *
 * @returns {void}
 */
export function buildDownloadSection (map: Map): void {
  const targetDOM = document.getElementById(domIds.download)
  const resultButtonContainer = document.createElement('ul')
  const downloadSectionTitle = document.createElement('h2')
  downloadSectionTitle.innerText = config.downloadSection.title

  const downloadClusterDataButtonContainer = document.createElement('li')
  downloadClusterDataButtonContainer.id = domIds.downloadClusterButton
  const downloadClusterDataButton = document.createElement('button')
  downloadClusterDataButton.type = 'button'
  downloadClusterDataButton.innerText = config.downloadSection.downloadClusterDataButton
  if (config.downloadSection.downloadClusterDataButtonHelpText !== undefined) {
    downloadClusterDataButton.title = config.downloadSection.downloadClusterDataButtonHelpText
  }
  downloadClusterDataButton.addEventListener('click', () => {
    const clustersToCalculatePropertyArray = getClusterDataFromClustersToCalculate()
    turnArrayOfJsonToCSVDownload(clustersToCalculatePropertyArray, config.downloadSection.clusterDataFileName)
  })
  downloadClusterDataButtonContainer.appendChild(downloadClusterDataButton)

  const downloadGeoJsonButtonContainer = document.createElement('li')
  const downloadGeoJsonButton = document.createElement('button')
  downloadGeoJsonButton.type = 'button'
  downloadGeoJsonButton.innerText = config.downloadSection.downloadRawGeoJsonButton
  if (config.downloadSection.downloadRawGeoJsonButtonHelpText !== undefined) {
    downloadGeoJsonButton.title = config.downloadSection.downloadRawGeoJsonButtonHelpText
  }
  downloadGeoJsonButton.addEventListener('click', () => {
    const geoJson = convertSelectionDictIntoGeoJson()
    downloadJson(geoJson, config.downloadSection.rawGeoJsonFilename)
  })
  downloadGeoJsonButtonContainer.appendChild(downloadGeoJsonButton)

  // Can create padding with this div
  const clearSelectionsButtonContainer = document.createElement('li')
  const clearSelectionsButton = document.createElement('button')
  clearSelectionsButton.type = 'button'
  clearSelectionsButton.innerText = config.downloadSection.clearSelectionsButton
  if (config.downloadSection.clearSelectionsButtonHelpText !== undefined) {
    clearSelectionsButton.title = config.downloadSection.clearSelectionsButtonHelpText
  }
  clearSelectionsButton.addEventListener('click', () => {
    clearSelectionsMade(map)
    filterSelectedClusters(map)
  })
  clearSelectionsButtonContainer.appendChild(clearSelectionsButton)

  resultButtonContainer.appendChild(downloadSectionTitle)
  resultButtonContainer.appendChild(downloadClusterDataButtonContainer)
  resultButtonContainer.appendChild(downloadGeoJsonButtonContainer)
  resultButtonContainer.appendChild(clearSelectionsButtonContainer)
  targetDOM?.appendChild(downloadSectionTitle)
  targetDOM?.appendChild(resultButtonContainer)
  checkIfDownloadButtonsShouldBeVisible()
}

/**
 * Checks if there are any selections within the selectionDict and displays the buttons
 *
 * @returns {void}
 */
export function checkIfDownloadButtonsShouldBeVisible (): void {
  const downloadsSection = document.getElementById(domIds.download)
  const downloadClusterButton = document.getElementById(domIds.downloadClusterButton)

  if (downloadsSection !== null && downloadClusterButton !== null) {
    if (Object.keys(selectionDict).length > 0) {
      downloadsSection.style.display = 'block'

      // If there is at least one cluster in the result data show the download csv button
      if (resultData.clusterCount > 0) {
        downloadClusterButton.style.display = 'block'
      } else {
        downloadClusterButton.style.display = 'none'
      }
    } else {
      downloadsSection.style.display = 'none'
    }
  }
}
