/**
 * @file This file contains any functions relating to the selection calculations.
 */

/* eslint-disable @typescript-eslint/no-dynamic-delete, @typescript-eslint/strict-boolean-expressions */

import buffer from '@turf/buffer'
import { polygon, point, multiPolygon } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

import type { Map } from 'ol'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { transform } from 'ol/proj'
import { LineString, MultiLineString, Point, Polygon } from 'ol/geom'

import { getCurrentSelectMode } from '../controls/selectControls'
import { CoordinateSystems, FeatureType, featurePropertyParameters, selectionModes } from '../enums/enums'
import { getActiveVectorLayerFromVectorLayerId, getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import { selectionDict } from './selectionEvents'
import { getLayerIdsThatAreVisible } from './layerEvents'
import { getLayerIdsByType, getCoordinatesFromFeature, roundToDecimalPlaces } from '../utils/utils'
import { getMaximumRange } from './sliderEvents'
import { calculateResultData } from './resultDataCalculation'
import { config } from '../utils/configExport'

export let clustersToCalculate: Record<string, Feature> = {}

/**
 * Filters the selected clusters on the map based on the current selection mode.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {Promise<void>} - A Promise that resolves once the filtering is completed.
 */
export function filterSelectedClusters (map: Map): void {
  const selectionMode = getCurrentSelectMode()
  const visibleLayers = getLayerIdsThatAreVisible()
  const clusterLayers = getLayerIdsByType(FeatureType.cluster)

  if (selectionMode === selectionModes.single) {
    // Clear the filter dict as we only want clusters related to the feature that was selected
    clearFilterDict()
    const selections = Object.keys(selectionDict)
    // This is single select so ensure there is something in the selections and pass it to the selection filter
    if (selections.length !== 0) clustersToCalculate = selectionFilter(map, selections[0], visibleLayers, clusterLayers)
  } else {
    clearFilterDict()
    for (const selectionId in selectionDict) {
      clustersToCalculate = selectionFilter(map, selectionId, visibleLayers, clusterLayers)
    }
  }
  calculateResultData(map)
}

/**
 * Filters and processes the selected feature based on its feature type.
 * Performs operations according to the selected feature's type, updating the clustersToCalculate dict.
 *
 * @param {Map} map - The OpenLayers Map object.
 * @param {string} selectionId - The ID of the selected feature.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @returns {Promise<void>} - A Promise that resolves once filtering and processing are completed.
 */
function selectionFilter (map: Map, selectionId: string, visibleLayers: string[], clusterLayers: string[]): Record<string, Feature> {
  const selectedFeature = selectionDict[selectionId]
  const selectedFeaturesLayerId = selectedFeature.get(featurePropertyParameters.layerId)
  const layerConfig = getLayerFromConfigWithLayerId(selectedFeaturesLayerId)

  if (layerConfig.featureType === FeatureType.cluster) {
    clustersToCalculate[selectionId] = selectedFeature
    return clustersToCalculate
  }

  if (layerConfig.featureType === FeatureType.boundary) {
    boundarySelectionLogic(selectedFeature, visibleLayers, clusterLayers, map)
    return clustersToCalculate
  }

  if (layerConfig.featureType === FeatureType.lineString) {
    lineStringFilteringLogic(selectedFeature, visibleLayers, clusterLayers, map)
    return clustersToCalculate
  }

  if (layerConfig.featureType === FeatureType.point) {
    pointFilteringLogic(selectedFeature, visibleLayers, clusterLayers, map)
    return clustersToCalculate
  }

  return clustersToCalculate
}

/**
 * Clears the filter dictionary of calculated clusters.
 * Removes all entries from the dictionary containing clusters to calculate.
 *
 * @returns {void}
 */
function clearFilterDict (): void {
  clustersToCalculate = {}
}

/**
 * Handles the selection logic for a boundary feature.
 * Retrieves coordinates, identifies geometry type, and filters clusters within the boundary from visible layers.
 *
 * @param {Feature} selectedFeature - The selected boundary feature.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {void}
 */
function boundarySelectionLogic (selectedFeature: Feature, visibleLayers: string[], clusterLayers: string[], map: Map): void {
  const coordinates = getCoordinatesFromFeature(selectedFeature)
  const featuresGeometry = selectedFeature.getGeometry()

  if (featuresGeometry instanceof MultiPolygon) {
    const boundary = multiPolygon(coordinates)
    filterForClustersWithinBoundary(boundary, visibleLayers, clusterLayers, map)
  } else {
    const boundary = polygon(coordinates)
    filterForClustersWithinBoundary(boundary, visibleLayers, clusterLayers, map)
  }
}

/**
 * Handles the filtering logic for a line string feature.
 * Converts , creates a buffer, and filters clusters within the buffer from visible layers.
 *
 * @param {Feature} selectedFeature - The selected line string feature.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {Promise<void>} - A Promise that resolves once the filtering is completed.
 */
function lineStringFilteringLogic (selectedFeature: Feature, visibleLayers: string[], clusterLayers: string[], map: Map): void {
  const maximumRange = getMaximumRange()
  const geoJson = transformFeatureToGeoJson(selectedFeature, CoordinateSystems.TurfJSDefault)
  if (maximumRange !== 0) {
    const bufferer = buffer(geoJson, maximumRange, { units: config.proximityCalculationDistanceMeasurement })
    filterForClustersWithinBuffer(bufferer, visibleLayers, clusterLayers, map)
  }
}

/**
 * Point filtering logic to find clusters within a specified range around a selected point.
 *
 * @param {Feature} selectedFeature - The selected feature to use as the center for filtering.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {void}
 */
function pointFilteringLogic (selectedFeature: Feature, visibleLayers: string[], clusterLayers: string[], map: Map): void {
  const maximumRange = getMaximumRange()
  const selectedFeatureCoordinates = getCoordinatesFromFeature(selectedFeature)
  const formattedCoords = transform(selectedFeatureCoordinates, CoordinateSystems.OpenLayersDefault, CoordinateSystems.TurfJSDefault)
  if (maximumRange !== 0) {
    const turfPoint = point(formattedCoords)
    const bufferer = buffer(turfPoint, maximumRange, { units: config.proximityCalculationDistanceMeasurement })
    filterForClustersWithinBuffer(bufferer, visibleLayers, clusterLayers, map)
  }
}

/**
 * Filters clusters within the specified boundary, if the cluster is visible and in the boundary it will add it to the clustersToCalculate dict.
 *
 * @param {Feature<Polygon, Properties>} boundary - The boundary polygon to filter clusters within.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {void}
 */
function filterForClustersWithinBoundary (boundary: Feature<Polygon, Properties>, visibleLayers: string[], clusterLayers: string[], map: Map): void {
  for (const clusterLayer of clusterLayers) {
    if (visibleLayers.includes(clusterLayer)) {
      const vectorLayer = getActiveVectorLayerFromVectorLayerId(clusterLayer, map)
      if (vectorLayer !== null) {
        vectorLayer.getSource().getFeatures().forEach((feature: Feature) => {
          const clusterCoordinates = point(getCoordinatesFromFeature(feature))
          if (feature.get(featurePropertyParameters.hydrogenDemand) !== 0 && booleanPointInPolygon(clusterCoordinates, boundary)) {
            const featureId = feature.getId()
            clustersToCalculate[featureId] = feature
          }
        })
      }
    }
  }
}

/**
 * Filters clusters within the specified buffer area, if the cluster is visible and in the buffer it will add it to the clustersToCalculate dict.
 * Since open layers and Turf use different coordinate systems we must transform the cluster points.
 *
 * @param {Feature<Polygon, Properties>} buffer - The buffer polygon to filter clusters within.
 * @param {string[]} visibleLayers - List of visible layer IDs.
 * @param {string[]} clusterLayers - List of cluster layer IDs.
 * @param {Map} map - The OpenLayers Map object.
 * @returns {void}
 */
function filterForClustersWithinBuffer (buffer: Feature<Polygon, Properties>, visibleLayers: string[], clusterLayers: string[], map: Map): void {
  for (const clusterLayer of clusterLayers) {
    if (visibleLayers.includes(clusterLayer)) {
      const vectorLayer = getActiveVectorLayerFromVectorLayerId(clusterLayer, map)
      if (vectorLayer !== null) {
        vectorLayer.getSource().getFeatures().forEach((feature: Feature) => {
          // If the hydrogen demand is 0 the cluster is invisible
          if (feature.get(featurePropertyParameters.hydrogenDemand) > 0) {
            const clusterCoordinates = getCoordinatesFromFeature(feature)
            const formattedCoords = transform(clusterCoordinates, CoordinateSystems.OpenLayersDefault, CoordinateSystems.TurfJSDefault)
            const formattedPoint = point(formattedCoords)

            if (booleanPointInPolygon(formattedPoint, buffer)) {
              const featureId = feature.getId()
              clustersToCalculate[featureId] = feature
            }
          }
        })
      }
    }
  }
}

/**
 * Transforms the provided feature into a GeoJSON object compatible with Turf.js
 * by converting the feature's coordinates from EPSG:3857 to EPSG:4326.
 *
 * @param {Feature} feature - The feature to be transformed.
 * @returns {Record<string, unknown>} - The transformed feature as a GeoJSON object.
 */
export function transformFeatureToGeoJson (feature: Feature, coordinateSystemToTransformTo: CoordinateSystems, roundingAccuracy: number | null = null): Record<string, unknown> {
  const geoJsonFormat = new GeoJSON()
  const coordinates = getCoordinatesFromFeature(feature)
  // Different feature types have different array structures hence why we check the feature type
  if (feature.getGeometry() instanceof MultiPolygon) {
    const transformedCoordinates = coordinates.map(polygon => {
      return polygon.map((line: number[][]) => {
        return line.map((point: number[]) => {
          let transformedPoint = transform(point, CoordinateSystems.OpenLayersDefault, coordinateSystemToTransformTo)
          if (roundingAccuracy !== null) transformedPoint = [roundToDecimalPlaces(transformedPoint[0], roundingAccuracy), roundToDecimalPlaces(transformedPoint[1], roundingAccuracy)]
          return transformedPoint
        })
      })
    })
    const transformedMultiPolygon = new MultiPolygon(transformedCoordinates)
    const transformedFeature = new Feature({ geometry: transformedMultiPolygon })
    const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature)
    return geoJson
  }

  if (feature.getGeometry() instanceof Polygon) {
    const transformedCoordinates = coordinates.map(line => {
      return line.map((point: number[]) => {
        let transformedPoint = transform(point, CoordinateSystems.OpenLayersDefault, coordinateSystemToTransformTo)
        if (roundingAccuracy !== null) transformedPoint = [roundToDecimalPlaces(transformedPoint[0], roundingAccuracy), roundToDecimalPlaces(transformedPoint[1], roundingAccuracy)]
        return transformedPoint
      })
    })
    const transformedPolygon = new Polygon(transformedCoordinates)
    const transformedFeature = new Feature({ geometry: transformedPolygon })
    const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature)
    return geoJson
  }

  if (feature.getGeometry() instanceof LineString) {
    const transformedCoordinates = coordinates.map((point: number[]) => {
      let transformedPoint = transform(point, CoordinateSystems.OpenLayersDefault, coordinateSystemToTransformTo)
      if (roundingAccuracy !== null) transformedPoint = [roundToDecimalPlaces(transformedPoint[0], roundingAccuracy), roundToDecimalPlaces(transformedPoint[1], roundingAccuracy)]
      return transformedPoint
    })
    const transformedLine = new LineString(transformedCoordinates)
    const transformedFeature = new Feature({ geometry: transformedLine })
    const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature)
    return geoJson
  }

  if (feature.getGeometry() instanceof MultiLineString) {
    const transformedCoordinates = coordinates.map(line => {
      return line.map((point: number[]) => {
        let transformedPoint = transform(point, CoordinateSystems.OpenLayersDefault, coordinateSystemToTransformTo)
        if (roundingAccuracy !== null) transformedPoint = [roundToDecimalPlaces(transformedPoint[0], roundingAccuracy), roundToDecimalPlaces(transformedPoint[1], roundingAccuracy)]
        return transformedPoint
      })
    })
    const transformedMultiLine = new MultiLineString(transformedCoordinates)
    const transformedFeature = new Feature({ geometry: transformedMultiLine })
    const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature)
    return geoJson
  }

  if (feature.getGeometry() instanceof Point) {
    let transformedCoordinates = transform(coordinates, CoordinateSystems.OpenLayersDefault, coordinateSystemToTransformTo)
    if (roundingAccuracy !== null) transformedCoordinates = [roundToDecimalPlaces(transformedCoordinates[0], roundingAccuracy), roundToDecimalPlaces(transformedCoordinates[1], roundingAccuracy)]
    const transformedPoint = new Point(transformedCoordinates)
    const transformedFeature = new Feature({ geometry: transformedPoint, properties: feature.getProperties().properties })
    const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature)
    return geoJson
  }
  return {}
}
