/**
 * @file This file contains any result data calculation related functions
 *
 */

/* eslint-disable @typescript-eslint/no-dynamic-delete, @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-argument */

import nearestPointOnLine from '@turf/nearest-point-on-line'
import polygonToLine from '@turf/polygon-to-line'

import { transform } from 'ol/proj'
import type { Feature, Map } from 'ol'
import MultiPolygon from 'ol/geom/MultiPolygon'

import { BreakdownDataKeys, CoordinateSystems, featurePropertyParameters } from '../enums/enums'
import { clustersToCalculate, transformFeatureToGeoJson } from './selectionCalculation'
import { getCurrentYearDisplayed } from '../controls/yearControls'
import type { ConfigDefinedLayer, ProximityDataObject, ResultDataObject } from '../structures/interfaces'
import { checkIfDataIsTopLevelExcludingYear, createProximityLayerList, filterGeoJsonPropertiesForMinimumCertainty, getCoordinatesFromFeature } from '../utils/utils'
import { getActiveVectorLayerFromVectorLayerId, getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import { getLayerIdsThatAreVisible } from './layerEvents'
import { checkIfDownloadButtonsShouldBeVisible } from '../construction/download'
import { checkIfResultsSectionShouldBeVisible } from '../construction/results'
import { config } from '../utils/configExport'
import { populateBasicResultTable } from '../construction/tables/basicResultTable'
import { createBreakdownTables } from '../construction/tables/breakdownTable'
import { checkIfProximityCalculationShouldBeVisible } from '../construction/tables/proximityTable'

export const resultData: ResultDataObject = {}

/**
 * Calculates and updates result data based on various computations from the map and designated clusters.
 *
 * @param {Object} map - The map object containing necessary data for calculations.
 * @returns {void}
 */
export function calculateResultData (map: Map): void {
  resultData.clusterCount = Object.keys(clustersToCalculate).length
  resultData.totalHydrogenDemand = sumHydrogenDemandFromClustersToCalculate()
  resultData.totalWeightedCertainty = calculateTotalWeightedCertainty()
  resultData.clusterBreakdown = calculateHydrogenAndCertaintyBreakdown()

  checkIfDownloadButtonsShouldBeVisible()
  checkIfProximityCalculationShouldBeVisible()
  checkIfResultsSectionShouldBeVisible()
  populateBasicResultTable()
  createBreakdownTables()
}

/**
 * Sums up the total hydrogen demand from clusters designated for calculation.
 *
 * @returns {number} The total sum of hydrogen demand from the clusters to be calculated.
 */
function sumHydrogenDemandFromClustersToCalculate (): number {
  let totalHydrogenDemand: number = 0
  for (const clusterId in clustersToCalculate) {
    const cluster = clustersToCalculate[clusterId]
    const hydrogenDemand: number = cluster.get(featurePropertyParameters.hydrogenDemand)
    totalHydrogenDemand += hydrogenDemand
  }
  return totalHydrogenDemand
}

/**
 * Calculates the total weighted certainty based on the certainty percentages of clusters.
 *
 * @returns {number} The total weighted certainty calculated from the clusters' certainty percentages.
 */
function calculateTotalWeightedCertainty (): number {
  let totalWeightedCertainty: number = 0
  for (const clusterId in clustersToCalculate) {
    const cluster = clustersToCalculate[clusterId]
    const weightedCertaintyPercentage = cluster.get(featurePropertyParameters.rawCertaintyPercentage)
    totalWeightedCertainty += weightedCertaintyPercentage
  }

  if (!totalWeightedCertainty) {
    return 0
  } else {
    return totalWeightedCertainty / Object.keys(clustersToCalculate).length
  }
}

/**
 * Calculates hydrogen demand and certainty breakdown based on cluster data properties.
 *
 * @returns {Record<string, unknown>} The breakdown dictionary with calculated hydrogen demand and certainty.
 */
function calculateHydrogenAndCertaintyBreakdown (): Record<string, unknown> {
  const currentYear = getCurrentYearDisplayed()
  let breakdownDict = {}
  let clusterLayerId: string
  for (const clusterId in clustersToCalculate) {
    const cluster = clustersToCalculate[clusterId]
    clusterLayerId = cluster.get(featurePropertyParameters.layerId)
    const layerConfig = getLayerFromConfigWithLayerId(clusterLayerId)
    const clusterProperties = cluster.getProperties()
    const clusterYearProperties = clusterProperties[layerConfig.columnsToReference.year][currentYear]
    const dataIsTopLevel: boolean = checkIfDataIsTopLevelExcludingYear(clusterLayerId)
    const filteredPropertyData = filterGeoJsonPropertiesForMinimumCertainty(clusterYearProperties, clusterLayerId)
    if (dataIsTopLevel) {
      calculateBreakdown(filteredPropertyData, breakdownDict, layerConfig)
    }
    if (layerConfig.structureWithinGeoJsonProperties.modes) {
      for (const mode in filteredPropertyData) {
        if (layerConfig.structureWithinGeoJsonProperties.duties) {
          for (const duty in filteredPropertyData[mode]) {
            breakdownDict = initBreakdownDict(breakdownDict, mode, duty)
            calculateBreakdown(filteredPropertyData[mode][duty], breakdownDict[mode][duty], layerConfig)
          }
        } else {
          // Data has just modes
          breakdownDict = initBreakdownDict(breakdownDict, mode)
          calculateBreakdown(filteredPropertyData[mode], breakdownDict[mode], layerConfig)
        }
      }
    } else if (layerConfig.structureWithinGeoJsonProperties.duties) {
      for (const duty in filteredPropertyData) {
        breakdownDict = initBreakdownDict(breakdownDict, duty)
        calculateBreakdown(filteredPropertyData[duty], breakdownDict[duty], layerConfig)
      }
    }
  }
  // Note this part will break if there is mutliple cluster layers with different structures within the properties
  if (clusterLayerId !== undefined) {
    cleanUpBreakdownDict(breakdownDict, clusterLayerId)
  }
  return breakdownDict
}

/**
 * Calculates and updates breakdown information based on given data and layer configuration.
 *
 * @param {Record<string, number>} dataLevelJson - The data at a specific level in JSON format.
 * @param {Record<string, number>} dataLevelBreakdownDict - The breakdown dictionary to be updated.
 * @param {ConfigDefinedLayer} layerConfig - The configuration for the defined layer.
 * @returns {void}
 */
function calculateBreakdown (dataLevelJson: Record<string, number>, dataLevelBreakdownDict: Record<string, number>, layerConfig: ConfigDefinedLayer): void {
  let totalHydrogenDemand = 0
  let totalCertainty = 0
  let totalClusterCount = 0
  const hydrogenDemand = dataLevelJson[layerConfig.columnsToReference.hydrogenDemand]
  const certaintyPercentage = dataLevelJson[layerConfig.columnsToReference.certaintyPercentage]

  const existingHydrogenDemand = dataLevelBreakdownDict[BreakdownDataKeys.hydrogenDemand]
  if (existingHydrogenDemand !== undefined) {
    totalHydrogenDemand = (existingHydrogenDemand + hydrogenDemand)
  } else {
    totalHydrogenDemand = hydrogenDemand
  }

  const existingCertainty = dataLevelBreakdownDict[BreakdownDataKeys.certainty]
  if (existingCertainty !== undefined) {
    totalCertainty = (existingCertainty + certaintyPercentage)
  } else {
    totalCertainty = certaintyPercentage
  }

  const existingCount = dataLevelBreakdownDict[BreakdownDataKeys.certaintyCount]
  if (existingCount !== undefined) {
    totalClusterCount += (existingCount + 1)
  } else {
    totalClusterCount += 1
  }

  dataLevelBreakdownDict[BreakdownDataKeys.hydrogenDemand] = totalHydrogenDemand
  dataLevelBreakdownDict[BreakdownDataKeys.certainty] = totalCertainty
  dataLevelBreakdownDict[BreakdownDataKeys.certaintyCount] = totalClusterCount
}

/**
 * Cleans up a breakdown dictionary based on specific cluster layer data and configuration.
 *
 * @param {Record<string, unknown>} breakdownDict - The breakdown dictionary to be cleaned up.
 * @param {string} clusterLayerId - The ID of the cluster layer to determine cleanup operations.
 * @returns {void}
 */
function cleanUpBreakdownDict (breakdownDict: Record<string, unknown>, clusterLayerId: string): void {
  const dataIsTopLevel: boolean = checkIfDataIsTopLevelExcludingYear(clusterLayerId)
  const layerConfig = getLayerFromConfigWithLayerId(clusterLayerId)

  if (dataIsTopLevel) {
    breakdownDict[BreakdownDataKeys.certainty] = breakdownDict[BreakdownDataKeys.certainty] / breakdownDict[BreakdownDataKeys.certaintyCount]
    delete breakdownDict[BreakdownDataKeys.certaintyCount]
  }

  if (layerConfig.structureWithinGeoJsonProperties.modes) {
    for (const mode in breakdownDict) {
      if (layerConfig.structureWithinGeoJsonProperties.duties) {
        for (const duty in breakdownDict[mode]) {
          breakdownDict[mode][duty][BreakdownDataKeys.certainty] = breakdownDict[mode][duty][BreakdownDataKeys.certainty] / breakdownDict[mode][duty][BreakdownDataKeys.certaintyCount]
          delete breakdownDict[mode][duty][BreakdownDataKeys.certaintyCount]
        }
      } else {
        // Data is just modes
        breakdownDict[mode][BreakdownDataKeys.certainty] = breakdownDict[mode][BreakdownDataKeys.certainty] / breakdownDict[mode][BreakdownDataKeys.certaintyCount]
        delete breakdownDict[mode][BreakdownDataKeys.certaintyCount]
      }
    }
  } else if (layerConfig.structureWithinGeoJsonProperties.duties) {
    // Data is just duties
    for (const duty in breakdownDict) {
      breakdownDict[duty][BreakdownDataKeys.certainty] = breakdownDict[duty][BreakdownDataKeys.certainty] / breakdownDict[duty][BreakdownDataKeys.certaintyCount]
      delete breakdownDict[duty][BreakdownDataKeys.certaintyCount]
    }
  }
}

/**
 * Initializes or updates a breakdown dictionary with optional year, mode and duty parameters.
 *
 * @param {Record<string, unknown>} breakdownDict - The breakdown dictionary to be initialized or updated.
 * @param {string | null} [mode=null] - The mode parameter, representing a specific mode within the breakdown dictionary.
 * @param {string | null} [duty=null] - The duty parameter, representing a specific duty within the breakdown dictionary.
 * @returns {Record<string, unknown>} - The formatted breakdownDict
 */
export function initBreakdownDict (breakdownDict: Record<string, unknown>, mode: string | null = null, duty: string | null = null, year: string | null = null): Record<string, unknown> {
  switch (true) {
    case year !== null && mode !== null && duty !== null:
      if (!breakdownDict[year]) breakdownDict[year] = {}
      if (!breakdownDict[year][mode]) breakdownDict[year][mode] = {}
      if (!breakdownDict[year][mode][duty]) breakdownDict[year][mode][duty] = {}
      break

    case year !== null && mode !== null:
      if (!breakdownDict[year]) breakdownDict[year] = {}
      if (!breakdownDict[year][mode]) breakdownDict[year][mode] = {}
      break

    case year !== null && duty !== null:
      if (!breakdownDict[year]) breakdownDict[year] = {}
      if (!breakdownDict[year][duty]) breakdownDict[year][duty] = {}
      break

    case mode !== null && duty !== null:
      if (!breakdownDict[mode]) breakdownDict[mode] = {}
      if (!breakdownDict[mode][duty]) breakdownDict[mode][duty] = {}
      break

    case year !== null:
      if (!breakdownDict[year]) breakdownDict[year] = {}
      break

    case mode !== null:
      if (!breakdownDict[mode]) breakdownDict[mode] = {}
      break

    case duty !== null:
      if (!breakdownDict[duty]) breakdownDict[duty] = {}
      break

    default:
      break
  }

  return breakdownDict
}

/**
 * Calculates proximity data for eligible layers on a map in relation to cluster points.
 *
 * @param {Map} map - The map object containing the vector layers.
 * @returns {Object} A dictionary object containing proximity data for each vector layer.
 */
export function calculateProximityData (map: Map): Record<string, ProximityDataObject> {
  const proximityVectorSourceDict = createProximityLayerList()
  const proximityDict: Record<string, ProximityDataObject> = {}
  const totalHydrogenDemand: number = sumHydrogenDemandFromClustersToCalculate()
  for (const layerId of proximityVectorSourceDict) {
    let minDistance = Infinity
    let maxDistance = 0
    let weightedAverageDistance = 0
    const resultDict: ProximityDataObject = {}
    const vectorLayer = getActiveVectorLayerFromVectorLayerId(layerId, map)
    const visibleLayers = getLayerIdsThatAreVisible()
    for (const clusterId in clustersToCalculate) {
      if (vectorLayer !== null && visibleLayers.includes(layerId)) {
        const cluster = clustersToCalculate[clusterId]
        const clusterCoordinates = getCoordinatesFromFeature(cluster)
        const weighting = (cluster.get(featurePropertyParameters.hydrogenDemand) / totalHydrogenDemand)
        // Convert the coordinates from EPSG:3857 (Open Layers specific system) to EPSG:4326 (Turf.js's specific system)
        const formattedCenterCords = transform(clusterCoordinates, CoordinateSystems.OpenLayersDefault, CoordinateSystems.TurfJSDefault)
        const closestFeature: Feature = vectorLayer.getSource().getClosestFeatureToCoordinate(clusterCoordinates)

        if (closestFeature.getGeometry() instanceof MultiPolygon) {
          // We need to transform the polygon into a line so we can calculate the nearest point
          const geoJson = transformFeatureToGeoJson(closestFeature, CoordinateSystems.TurfJSDefault)
          const formattedPolygonLine = polygonToLine(geoJson)
          const nearestPointToFeature = nearestPointOnLine(formattedPolygonLine, formattedCenterCords, { units: config.proximityCalculationDistanceMeasurement })
          const calculatedDistance = nearestPointToFeature.properties?.dist ?? 0

          if (minDistance > calculatedDistance) {
            minDistance = calculatedDistance
          }
          if (maxDistance < calculatedDistance) {
            maxDistance = calculatedDistance
          }

          weightedAverageDistance += (weighting * calculatedDistance)
        } else {
          const geoJson = transformFeatureToGeoJson(closestFeature, CoordinateSystems.TurfJSDefault)
          const nearestPointToFeature = nearestPointOnLine(geoJson, formattedCenterCords, { units: config.proximityCalculationDistanceMeasurement })
          const calculatedDistance = nearestPointToFeature.properties?.dist ?? 0

          if (minDistance > calculatedDistance) {
            minDistance = calculatedDistance
          }
          if (maxDistance < calculatedDistance) {
            maxDistance = calculatedDistance
          }

          weightedAverageDistance += (weighting * calculatedDistance)
        }
      }
    }
    // Need to figure out weighted certainty correctly
    if (vectorLayer !== null) {
      if (minDistance !== Infinity && maxDistance !== 0) {
        resultDict.maxDistance = maxDistance
        resultDict.minDistance = minDistance
        resultDict.weightedAverageDistance = weightedAverageDistance
        proximityDict[layerId] = resultDict
      }
    }
  }
  return proximityDict
}
