/**
 * @file This file contains functions that construct the results section within the detail panel.
 *
 */

import type { Map } from 'ol'
import { domIds } from '../enums/enums'
import { config } from '../utils/configExport'
import { buildBasicTableSection } from './tables/basicResultTable'
import { checkIfProximityCalculationShouldBeVisible, createProximityResultSection } from './tables/proximityTable'
import { buildBreakdownSection } from './tables/breakdownTable'
import type { TableConfigObject } from '../structures/interfaces'
import { roundToDecimalPlaces } from '../utils/utils'
import { closeGuidance } from './guidance'
import { resultData } from '../events/resultDataCalculation'

/**
 * Builds and appends the results section to the specified DOM element.
 *
 * @param {Map} map - The map object used for processing and displaying results.
 *
 * @returns {void}
 */
export function buildResultsSection (map: Map): void {
  const targetDOM = document.getElementById(domIds.result)
  const fragment = new DocumentFragment()

  // Create results section detail element
  const summaryElement = document.createElement('summary')
  summaryElement.textContent = config.resultsConfiguration.title
  const detailsElement = document.createElement('details')
  detailsElement.id = domIds.resultDetailElement

  // Create basic result section
  detailsElement.appendChild(summaryElement)
  const resultDataContainer = buildBasicTableSection()
  detailsElement.appendChild(resultDataContainer)

  // Breakdown section
  const breakdownContainer = buildBreakdownSection()
  detailsElement.appendChild(breakdownContainer)

  // Setup proximity section
  const proximityDetailElement = createProximityResultSection(map)
  detailsElement.appendChild(proximityDetailElement)

  fragment.appendChild(detailsElement)
  targetDOM?.appendChild(fragment)

  // Check if any sections should be hidden
  checkIfResultsSectionShouldBeVisible()
  checkIfProximityCalculationShouldBeVisible()
}

/**
 * Checks whether the results section should be visible based on the current state of the selection dictionary.
 * If there are selected items, the results section is displayed; otherwise, it is hidden.
 *
 * @returns {void}
 */
export function checkIfResultsSectionShouldBeVisible (): void {
  const resultsSection = document.getElementById(domIds.result)
  const resultDetailElement = document.getElementById(domIds.resultDetailElement)

  if (resultsSection !== null && resultDetailElement !== null) {
    if (resultData.clusterCount > 0) {
      resultDetailElement.open = true
      resultDetailElement.style.display = 'block'
      closeGuidance()
    } else {
      resultDetailElement.style.display = 'none'
    }
  }
}

/**
 * Creates a base result table structure with specified IDs for the table, table header, and table body for use later.
 *
 * @param {string} tableId - The ID for the table element.
 * @param {string} tableHeaderId - The ID for the table header element (thead).
 * @param {string} tableBodyId - The ID for the table body element (tbody).
 *
 * @returns {HTMLDivElement} - The container div element containing the base result table structure.
 */
export function createBaseResultTable (tableId: string, tableHeaderId: string, tableBodyId: string): HTMLDivElement {
  const tableContainer = document.createElement('div')
  const table = document.createElement('table')
  table.id = tableId
  const tableHeader = document.createElement('thead')
  tableHeader.id = tableHeaderId
  const tableBody = document.createElement('tbody')
  tableBody.id = tableBodyId

  tableContainer.appendChild(table)
  tableContainer.appendChild(tableHeader)
  tableContainer.appendChild(tableBody)
  return tableContainer
}

/**
 * Creates text content for a result table based on the provided configuration.
 *
 * @param {TableConfigObject} entriesConfig - Configuration object for the table entry.
 * @param {number} value - The numeric value to be formatted.
 * @returns {string} The formatted text content for the result table.
 */
export function createTextContentForResultTable (entriesConfig: TableConfigObject, value: number): string {
  let textContent = ''

  if (entriesConfig.isColumnToReference) {
    if (entriesConfig.isPercentage) {
      const percentageValue = (value * 100)
      const roundedPercentage = roundToDecimalPlaces(percentageValue, entriesConfig.decimalRoundingAccuracy)
      textContent = `${roundedPercentage}%`
    } else {
      textContent = roundToDecimalPlaces(value, entriesConfig.decimalRoundingAccuracy).toLocaleString(config.resultsConfiguration.stringFormatting)
    }
  }
  return textContent
}
