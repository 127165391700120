/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @file This is the default file - Contains defaults for some config variables, if the config variable is empty the value will be taken from here
 *       If a key has the type configKeyType.criticalConfigKey this means the application cannot run correctly without the value being populated
 */
import { configKeyType, selectionModes } from '../enums/enums'

export const defaultConfigData: Record<string, any> = {

  // The tool version - This is the codebase version number - if changes to the codebase have been made this number should be updated
  toolVersion: 0,

  schemaVersion: 1, // If the schema of the database has been changed this value should be updated CANNOT BE BELOW 0

  configVersion: 1, // Version number for the configfile - If the config file has changed please update this value

  // The data version for the config file - If a variable has been changed this number must be updated,
  // this includes any changes to underlying GeoJSON data.
  dataVersion: configKeyType.criticalConfigKey,

  indexDBName: 'visualiserDB', // Database name used for the indexedDB used for storing and caching vector sources

  dataVersionTableName: 'dataVersions', // Table name used within the indexedDB - used for storing the existing data version

  vectorSourceTableName: 'vectorSources', // Table name used within the indexedDB - used for storing and caching vector sources

  warning: '', // Important message permanently displayed to the user in red above the results panel

  clusterRadiusScalingFactor: 0.01, // Scaling factor used in displaying clusters

  mapCentre: [-2, 55], // Initial map longitude, latitude

  mapZoom: 6, // Initial map zoom

  maximumCertainty: 0.9, // Maximum value for certainty scaling

  minimumCertainty: 0.2, // Minimum value for certainty scaling

  outputDecimalAccuracy: 5, // Amount of decimal points used for coordinates in the CSV output

  toolTipHydrogenDemandRoundingDecimalAccuracy: 0, // Tooltip hydrogen demand decimal rounding accuracy

  toolTipCertaintyRoundingDecimalAccuracy: -1, // Tooltip certainty decimal rounding accuracy

  proximityCalculationDistanceMeasurement: 'kilometers', // Turf related value hence american spelling

  defaultYear: configKeyType.criticalConfigKey, // Year that is used as the default for the inital state - must be in years

  years: configKeyType.criticalConfigKey, // Years - this is used within our year slider & used for filtering

  // Map Z Index - This is used to set the z index of the map (The Order that the layers are presented on the map)
  // This must be a list of layer id's. General order is: highest tier boundary, lowest tier boundary, clusters, lines, points
  mapZIndex: [],

  legendIndex: [], // Legend Index - This is used to set the order of layers in the legend - this MUST be a list of layer id's

  layers: configKeyType.criticalConfigKey, // Layers - This is a dict of all layers present within the application

  controlIDPrefix: 'control_', // Used for setting unique ID's within the control generation functions

  advancedControl: configKeyType.criticalConfigKey, // Config component for the advanced control section

  layerControl: { // Config component for the layer control
    controlText: { // Text found within control
      title: 'Display and analyse', // Title of the layer control
      helpText: 'Add data layers to the map, whose features can then be selected and used in analysis' // Generic tooltip for layer selection
    }
  },

  selectControl: {
    selectionColor: '#FCEC19', // Color used when features are selected
    selectionStrokeColor: '#ADFFA2', // Color used for the stroke colour around clusters
    selectionStrokeWidth: 3, // Width of the stroke around clusters
    selectionOpacity: 0.3, // Opacity of areas of linestrings that have been selected
    selectionHitTolerance: 10, // Hit tolerance for selecting line features
    selectionMode: selectionModes.single, // Selection mode for the application - must be this enum type

    controlText: {
      title: 'Select',
      drawBoxSelectDisplayName: 'Draw box',
      drawBoxSelectHelpText: 'Draw a marquee box with the mouse to select all visible features within',
      multiSelectDisplayName: 'Multiple (or pan)',
      multiSelectHelpText: 'Select any number of map features - selecting any a second time removes them from the selection',
      singleSelectDisplayName: 'Single (or pan)',
      singleSelectHelpText: 'Select only one map feature at a time',
      clearSelectionButtonText: 'Clear',
      clearSelectionButtonHelpText: 'Clears all selected features from the map'
    }
  },

  yearControl: { // Config component for the layer control
    controlText: { // Text found within control
      title: 'Period (years)', // Title of the year control
      leftButtonText: '<', // Text found on the left side button of the year control
      leftButtonHelpText: 'Previous year period', // Tool tip for left side button of year control
      rightButtonText: '>', // Text found on the right side button of the year control
      rightButtonHelpText: 'Next year period', // Tool tip for right side button of year control
      buttonDisabledColor: '#FC1919', // Colour used to singal the button is disabled
      yearDisplayHelpText: 'The year period in which potential hydrogen demand is new' // Tool tip for the year display div
    }
  },

  downloadSection: { // Config related to the download section
    title: 'Download raw data or reset selection',
    clearSelectionsButton: 'Clear all selections',
    clearSelectionsButtonHelpText: 'Clears all selected features from the map',
    downloadClusterDataButton: 'CSV of clusters included',
    downloadClusterDataButtonHelpText: 'Download a CSV consisting data for all individual clusters in their current selection, for import into Excel',
    clusterDataFileName: 'cluster-details.csv',
    downloadRawGeoJsonButton: 'GeoJSON of current map selection',
    downloadRawGeoJsonButtonHelpText: 'Download your current map selection as GeoJSON, for import into GIS packages',
    rawGeoJsonFilename: 'selection.geojson'
  },

  resultsConfiguration: { // Config related to the results section
    title: 'Potential new hydrogen demand',
    stringFormatting: 'en-GB',
    basicResultSection: { // Summary
      title: 'Summary',
      subtitle: 'The potential hydrogen demand most likely to be new in the year period selected. Analysis displayed here includes any clusters directly selected, clusters within selected boundaries, and clusters within range of selected infrastructure.',
      tableConfig: {
        clusterCount: {
          displayName: 'Demand cluster(s)', // Display name used as the header within the table
          decimalRoundingAccuracy: 0, // Rounding accuracy for the value in the table
          isPercentage: false, // Flag for if the value is in the form of a percentage
          isColumnToReference: true // Flag for if the tableConfig ID is a column to reference
        },
        totalHydrogenDemand: {
          displayName: 'Daily hydrogen demand (kg)',
          decimalRoundingAccuracy: -1,
          isPercentage: false,
          isColumnToReference: true
        },
        totalWeightedCertainty: {
          displayName: 'Certainty (%, weighted)',
          decimalRoundingAccuracy: 0,
          isPercentage: true,
          isColumnToReference: true
        }
      }
    },
    proximityResultSection: { // Nearest infrastructure
      title: 'Proximity to infrastructure',
      subtitle: 'Distances from each demand cluster to its nearest part of each of the infrastructure networks mapped.',
      tableConfig: {
        infrastructure: {
          displayName: 'Infrastructure',
          decimalRoundingAccuracy: 0,
          isPercentage: false,
          isColumnToReference: false
        },
        minDistance: {
          displayName: 'Nearest (km)',
          decimalRoundingAccuracy: 1,
          isPercentage: false,
          isColumnToReference: true
        },
        maxDistance: {
          displayName: 'Furthest (km)',
          decimalRoundingAccuracy: 1,
          isPercentage: false,
          isColumnToReference: true
        },
        weightedAverageDistance: {
          displayName: 'Average (km, weighted)',
          decimalRoundingAccuracy: 1,
          isPercentage: false,
          isColumnToReference: true
        }
      }
    },
    breakdownResultSection: { // Mode and duty splits
      title: 'By mode and duty',
      subtitle: 'A breakdown of demand by mode and vehicle duty. For full data by individual cluster, download "Cluster details as CSV" below.',
      subsectionHelpText: 'Show explanation', // Hint shown against each expandable mode/duty
      tableConfig: {
        duty: {
          displayName: 'Mode and duty',
          decimalRoundingAccuracy: 0,
          isPercentage: false,
          isColumnToReference: false
        },
        totalHydrogenDemand: {
          displayName: 'Daily hydrogen demand (kg)',
          decimalRoundingAccuracy: 0,
          isPercentage: false,
          isColumnToReference: true
        },
        certainty: {
          displayName: 'Certainty (%, weighted)',
          decimalRoundingAccuracy: 0,
          isPercentage: true,
          isColumnToReference: true
        }
      }
    }
  },

  // Non-result guidance text, in display order.
  // Each entry is rendered as an expandable block, where only title is visible in normal use.
  // The first entry is an exception, visible on first use - this should be used for at least introductory text.
  // The content may contain rich HTML, but no Javascript functions.
  guidance: [
    {
      title: 'Introduction',
      content: '<h1>Hydrogen Mobility Visualiser</h1>'
    },
    // Extendable here. Legal should be placed at the bottom.
    {
      title: 'Terms of use',
      content: `<p>Tool &copy; Copyright 2023 by
      <a href="https://www.erm.com/" title="Formerly Element Energy"
      target="_blank">The ERM International Group Limited (ERM)</a>.
      All Rights Reserved.</p>
      <p>Base map sourced from <a href="https://www.openstreetmap.org/copyright"
      target="_blank">OpenStreetMap</a> contributors.</p>`
    }
  ]

}
