/**
 * @file This file contains any functions relating to the creation of styling for individual features.
 */

import { Style, Circle as CircleStyle, Fill, Stroke } from 'ol/style'
import * as olColor from 'ol/color'

import { getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import type { ConfigDefinedLayer } from '../structures/interfaces'
import { config } from '../utils/configExport'

/**
 * Creates a default style for a feature with no fill and layer specific stroke properties.
 *
 * @param {string} layerId - The identifier of the layer for which the style is being created.
 * @returns {Style} A Style object representing the default feature style.
 */
export function createDefaultNoFillFeatureStyle (layerId: string): Style {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const noFillStyle = new Style({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0)'
    }),
    stroke: new Stroke({
      color: layer.primaryColor, // Use the primaryColour here
      width: layer.featureWidth // Adjust the line width as needed for polygon outlines
    })
  })
  return noFillStyle
}

/**
 * Creates a selected style for a feature with no fill and layer specific stroke properties.
 *
 * @param {string} layerId - The identifier of the layer for which the style is being created.
 * @returns {Style} A Style object representing the selected feature style.
 */
export function createSelectedFeatureStyle (layerId: string): Style {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)

  const selectedFillColor: olColor.Color = olColor.asArray(String(config.selectControl.selectionColor))
  selectedFillColor[3] = (config.selectControl.selectionOpacity)

  const updatedStyle = new Style({
    fill: new Fill({
      color: selectedFillColor
    }),
    stroke: new Stroke({
      color: config.selectControl.selectionColor, // Use the primaryColour here
      width: layer.featureWidth // Adjust the line width as needed for polygon outlines
    })
  })
  return updatedStyle
}

/**
 * Creates a style for a selected cluster.
 *
 * @param {number} hydrogenDemand - The hydrogen demand used with the config scaling factor determine the circle's area.
 * @param {number} certaintyPercentage - The certainty percentage to adjust fill transparency.
 * @returns {Style} A Style object representing the selected cluster style.
 */
export function createSelectedClusterStyle (hydrogenDemand: number, certaintyPercentage: number): Style {
  const rgbaColor: olColor.Color = olColor.asArray(String(config.selectControl.selectionColor))

  const scaledArea = hydrogenDemand * config.clusterRadiusScalingFactor

  rgbaColor[3] = certaintyPercentage
  const selectedStyle = new Style({
    image: new CircleStyle({
      radius: Math.sqrt(scaledArea) / Math.PI,
      fill: new Fill({
        color: rgbaColor
      }),
      stroke: new Stroke({
        color: config.selectControl.selectionStrokeColor,
        width: config.selectControl.selectionStrokeWidth
      })
    })
  })
  return selectedStyle
}

/**
 * Creates the default styling for a cluster.
 *
 * @param {number} hydrogenDemand - The hydrogen demand used with the config scaling factor determine the circle's area.
 * @param {number} certaintyPercentage - The certainty percentage to adjust fill transparency.
 * @returns {Style} A Style object representing the default cluster style.
 */
export function createDefaultClusterStyle (hydrogenDemand: number, certainty: number, layerId: string): Style {
  // If hydrogenDemand & certainty are zero the layer is not displayed as radius and opacity is zero
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const rgbaColor = olColor.asArray(layer.primaryColor)
  const colourRGBAString = `rgba(${rgbaColor[0]}, ${rgbaColor[1]}, ${rgbaColor[2]}, ${certainty})`

  const scaledArea = hydrogenDemand * config.clusterRadiusScalingFactor

  const defaultStyle = new Style({
    image: new CircleStyle({
      radius: Math.sqrt(scaledArea) / Math.PI,
      fill: new Fill({
        color: colourRGBAString
      })
    })
  })
  return defaultStyle
}

/**
 * Create a style object used to style non cluster points.
 *
 * @param {string} layerId - The identifier of the layer.
 * @param {string} fillColour - The fill color for the point style.
 * @returns {Style} The created point style.
 */
export function createPointStyle (layerId: string, fillColour: string): Style {
  const layer: ConfigDefinedLayer = getLayerFromConfigWithLayerId(layerId)
  const rgbaColor = olColor.asArray(fillColour)
  rgbaColor[3] = config.nonClusterPointOpacity
  const defaultStyle = new Style({
    image: new CircleStyle({
      radius: layer.featureWidth,
      fill: new Fill({
        color: rgbaColor
      })
    })
  })
  return defaultStyle
}
