/**
 * @file This file contains any functions relating to the mode events.
 */

import { config } from '../utils/configExport'
import { filterSelectedClusters } from './selectionCalculation'
import { removeSelectionsThatAreInvisible, styleAllSelectionsWithinSelectionDict } from './selectionEvents'
import { updateLayersFromYearChange } from './yearEvents'

/**
 * Retrieves a list of active modes based on the checked checkboxes.
 *
 * @returns {string[]} An array of the active modes.(In their short form)
 */
export function getActiveModesList (): string[] {
  const modesDict = config.advancedControl.modes
  const modeList: string[] = []
  for (const modeId in modesDict) {
    const modeCheckBox: HTMLInputElement = document.getElementById(modeId)
    if (modeCheckBox.checked) {
      modeList.push(modesDict[modeId].propertyColumn)
    }
  }
  return modeList
}

/**
 * Updates clusters on a map based on the selected mode.
 *
 * @param {Map} map - The map object to update.
 * @returns {void}
 */
export function updateClustersUponModeSelection (map: Map): void {
  updateLayersFromYearChange(map)
  removeSelectionsThatAreInvisible(map)
  styleAllSelectionsWithinSelectionDict(map)
  filterSelectedClusters(map)
}
