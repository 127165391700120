/**
 * @file This file contains any functions relating to download events.
 */

/* eslint-disable @typescript-eslint/no-dynamic-delete */

import type { Feature } from 'ol'
import { transform } from 'ol/proj'

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { selectionDict } from './selectionEvents'
import { clustersToCalculate, transformFeatureToGeoJson } from './selectionCalculation'
import { getLayerFromConfigWithLayerId } from '../processing/vectorLayers'
import { CoordinateSystems, FeatureType, featurePropertyParameters } from '../enums/enums'
import { checkIfActiveModesIsInFeatureProperties, checkIfDataIsTopLevelExcludingYear, createDutyLookupDict, createModeLookupDict, filterGeoJsonPropertiesForMinimumCertainty, roundToDecimalPlaces } from '../utils/utils'
import { getCurrentYearDisplayed } from '../controls/yearControls'
import { getActiveModesList } from './modeEvents'
import { config } from '../utils/configExport'

/**
 * This function converts any selections into their raw geojson and returns a feature collection of
 * selected features.
 *
 * @returns {void}
 */
export function convertSelectionDictIntoGeoJson (): Record<string, unknown> {
  const baseGeoJsonObject = {
    type: 'FeatureCollection',
    features: []
  }

  for (const featureId in selectionDict) {
    const selectedFeature: Feature = selectionDict[featureId]
    // Duplicate the feature so when coordinates are transformed the feature is not lost on the map
    const duplicatedFeature: Feature = selectedFeature.clone()
    // Convert coordinate system to WGS84 and round values to the config supplied accuracy
    const properties = duplicatedFeature.getProperties()
    delete properties.geometry
    const selectedFeatureLayerId = duplicatedFeature.get(featurePropertyParameters.layerId)
    const layerConfig = getLayerFromConfigWithLayerId(selectedFeatureLayerId)
    if (layerConfig.featureType === FeatureType.cluster) {
      // Remove year and apply output rounding on certainty percentage
      delete properties[layerConfig.columnsToReference.year]
      properties[featurePropertyParameters.rawCertaintyPercentage] = roundToDecimalPlaces(properties[featurePropertyParameters.rawCertaintyPercentage], config.outputDecimalAccuracy)
    }
    const geoJsonFeature = transformFeatureToGeoJson(duplicatedFeature, CoordinateSystems.TurfJSDefault, config.outputDecimalAccuracy)
    geoJsonFeature.properties = properties
    baseGeoJsonObject.features.push(geoJsonFeature)
  }
  return baseGeoJsonObject
}

/**
 * This function gets the property data for each cluster used in the result data and appends them to an array
 *
 * @returns {Array<Record<string, unknown>>} - List of property data objects
 */
export function getClusterDataFromClustersToCalculate (): Array<Record<string, unknown>> {
  const clusterPropertyData = []
  const dutyLookupDict = createDutyLookupDict()
  const modeLookupDict = createModeLookupDict()
  const currentYear = getCurrentYearDisplayed()
  const activeModes = getActiveModesList()

  for (const clusterId in clustersToCalculate) {
    const cluster: Feature = clustersToCalculate[clusterId]
    const transformedClusterPointCoordinates = getTransformedCoordinatesforCSVOutput(cluster)
    const clusterLayerId = cluster.get(featurePropertyParameters.layerId)
    const layerConfig: ConfigDefinedLayer = getLayerFromConfigWithLayerId(clusterLayerId)
    const propertyData = cluster.getProperties()
    const dataIsTopLevel = checkIfDataIsTopLevelExcludingYear(clusterLayerId)

    if (layerConfig.structureWithinGeoJsonProperties.year) {
      const yearData = propertyData[layerConfig.columnsToReference.year]
      const yearFilteredData = yearData[currentYear]
      const modeIsInProperties: boolean = checkIfActiveModesIsInFeatureProperties(activeModes, yearFilteredData)

      if (dataIsTopLevel) {
        const clusterHydrogenDemand = yearFilteredData[layerConfig.columnsToReference.hydrogenDemand]
        const clusterCertaintyPercentage = yearFilteredData[layerConfig.columnsToReference.certaintyPercentage]
        const clusterPropertyEntry = {
          clusterLayerId: clusterId,
          longitude: transformedClusterPointCoordinates[0],
          latitude: transformedClusterPointCoordinates[1],
          year: currentYear,
          hydrogenDemand: clusterHydrogenDemand,
          certaintyPercentage: clusterCertaintyPercentage
        }
        clusterPropertyData.push(clusterPropertyEntry)
      } else {
        const filteredData = filterGeoJsonPropertiesForMinimumCertainty(yearFilteredData, clusterLayerId)
        for (const mode in filteredData) {
          if (modeIsInProperties) {
            if (layerConfig.structureWithinGeoJsonProperties.modes && layerConfig.structureWithinGeoJsonProperties.duties) {
              const dutyData = filteredData[mode]
              for (const duty in dutyData) {
                const dataLevelDict = dutyData[duty]
                const clusterHydrogenDemand = dataLevelDict[layerConfig.columnsToReference.hydrogenDemand]
                const clusterCertaintyPercentage = dataLevelDict[layerConfig.columnsToReference.certaintyPercentage]
                const readableMode = modeLookupDict[mode]
                const readableDuty = dutyLookupDict[duty]
                const clusterPropertyEntry = {
                  clusterLayerId: clusterId,
                  longitude: transformedClusterPointCoordinates[0],
                  latitude: transformedClusterPointCoordinates[1],
                  year: currentYear,
                  mode: readableMode,
                  duty: readableDuty,
                  hydrogenDemand: clusterHydrogenDemand,
                  certaintyPercentage: clusterCertaintyPercentage
                }
                clusterPropertyData.push(clusterPropertyEntry)
              }
            }
          } else if (layerConfig.structureWithinGeoJsonProperties.duties) {
            // The mode we have is actually the duty
            const dataLevelDict = yearFilteredData[mode]
            const clusterHydrogenDemand = dataLevelDict[layerConfig.columnsToReference.hydrogenDemand]
            const clusterCertaintyPercentage = dataLevelDict[layerConfig.columnsToReference.certaintyPercentage]
            const readableDuty = dutyLookupDict[mode]
            const clusterPropertyEntry = {
              clusterLayerId: clusterId,
              longitude: transformedClusterPointCoordinates[0],
              latitude: transformedClusterPointCoordinates[1],
              year: currentYear,
              duty: readableDuty,
              hydrogenDemand: clusterHydrogenDemand,
              certaintyPercentage: clusterCertaintyPercentage
            }
            clusterPropertyData.push(clusterPropertyEntry)
          }
        }
      }
    }
  }
  return clusterPropertyData
}

/**
 * This function retrieves and transforms a clusters coordinates and then rounds it to a set amount of decimal places
 * which is supplied from config.
 *
 * @param {Feature} feature - The feature containing the coordinates.
 * @returns {number[]} An array containing the rounded longitude and latitude coordinates.
 */
function getTransformedCoordinatesforCSVOutput (feature: Feature): number[] {
  const clusterPointCoordinates = feature.getGeometry()?.flatCoordinates
  const transformedCoordinates = transform(clusterPointCoordinates, CoordinateSystems.OpenLayersDefault, CoordinateSystems.TurfJSDefault)
  const roundedLongitude = roundToDecimalPlaces(transformedCoordinates[0], config.outputDecimalAccuracy)
  const roundedLatitude = roundToDecimalPlaces(transformedCoordinates[1], config.outputDecimalAccuracy)
  return [roundedLongitude, roundedLatitude]
}
