/**
 * @file This file contains any functions related to creating WFS vector sources
 *
 */

import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { WFSSourceType, featurePropertyParameters } from '../enums/enums'

/**
 * Creates a VectorSource for a Web Feature Service (WFS) layer using a given layer configuration.
 *
 * @param {ConfigDefinedLayer} layer - The layer configuration object for the WFS layer.
 *
 * @returns {VectorSource} A VectorSource configured for the specified WFS layer.
 */
export function createWFSVectorSource (layer: ConfigDefinedLayer, layerId: string): VectorSource {
  const vectorSource = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      const baseURL = buildWFSUrl(layer)
      return (
        baseURL + '&bbox=' + extent.join(',')
      )
    },
    strategy: bboxStrategy
  })

  // Set layerId on each WFS feature
  vectorSource.on('addfeature', function (event) {
    const feature = event.feature
    if (feature !== undefined) feature.set(featurePropertyParameters.layerId, layerId)
  })
  return vectorSource
}

/**
 * Builds a Web Feature Service (WFS) URL based on the provided layer configuration.
 *
 * @param {ConfigDefinedLayer} layer - The layer configuration object.
 * @returns {string} - The constructed WFS URL.
 */
function buildWFSUrl (layer: ConfigDefinedLayer): string {
  const baseUrl = layer.path
  let boundariesToKeep: string = ''
  if (layer.wfsFilterProperties !== undefined) {
    // Adds URL encoded quotes and commas to the query string
    // %27 = '
    // %2C%20 = ,
    if (layer.wfsFilterProperties.boundariesToKeep.length === 1) {
      boundariesToKeep = `'${layer.wfsFilterProperties.boundariesToKeep[0]}'`
    } else {
      for (let index = 0; index < layer.wfsFilterProperties.boundariesToKeep.length; index++) {
        const boundary = layer.wfsFilterProperties.boundariesToKeep[index]
        if (index === 0) boundariesToKeep += `'${boundary}',`
        else if ((index + 1) === layer.wfsFilterProperties.boundariesToKeep.length) boundariesToKeep += `'${boundary}'`
        else boundariesToKeep += `'${boundary}' ,`
      }
    }
    return buildSpecificWFSURLStructure(baseUrl, layer.wfsFilterProperties.boundaryFilterColumn, boundariesToKeep, layer.wfsFilterProperties.wfsType)
  }
  return baseUrl
}

/**
 * Build a specific WFS URL structure.
 *
 * @param {string} baseURL - The base URL.
 * @param {string} columnToFilterFor - The WFS filter column.
 * @param {string} boundariesToKeep - Boundaries to include in the WFS filter.
 * @param {WFSSourceType} wfsType - The WFS type.
 *
 * @returns {string} - The constructed WFS URL.
 */
function buildSpecificWFSURLStructure (baseURL: string, columnToFilterFor: string, boundariesToKeep: string, wfsType: WFSSourceType): string {
  const URLQuery: string[] = []
  // Extra entries can be added here - please note we must add the method to filter for features & the method to get all features
  switch (wfsType) {
    case WFSSourceType.ONS:
      URLQuery.push('outFields=*')
      if (boundariesToKeep === '') {
        // Add clause of where 1=1 so we get everything in the WFS source
        URLQuery.push('where=1=1')
      } else {
        URLQuery.push(`where=(${columnToFilterFor} IN (${boundariesToKeep}))`)
      }
      break
    default:
      console.warn('WFS type has not been implemented')
  }
  return `${baseURL}&${URLQuery.join('&')}`
}
