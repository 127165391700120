/**
 * @file This file contains functions that construct guidance content within the detail panel.
 *
 */

import { config } from '../utils/configExport'
import { domIds } from '../enums/enums'

/**
 * Populates guidance section from Config, opening the first topic.
 * Call on first load only.
 *
 * @returns {void}
 */
export function buildGuidance (): void {
  const targetDOM = document.getElementById(domIds.guidance)
  if (targetDOM === null || !Array.isArray(config?.guidance)) return
  targetDOM.innerHTML = ''
  for (const guide of config.guidance) {
    const summaryElement = document.createElement('summary')
    summaryElement.textContent = guide?.title ?? ''
    summaryElement.title = `Expand/collapse information on ${guide?.title}`
    const detailsElement = document.createElement('details')
    detailsElement.innerHTML = guide?.content ?? ''
    detailsElement.appendChild(summaryElement)
    targetDOM.appendChild(detailsElement)
  }
  targetDOM.firstElementChild?.setAttribute('open', 'open')
}

/**
 * Closes all top-level guidance topics.
 * Call when writing results.
 *
 * @returns {void}
 */
export function closeGuidance (): void {
  const targetDOM = document.getElementById(domIds.guidance)
  if (targetDOM === null) return
  const detailsElements = targetDOM.getElementsByTagName('details')
  for (const detailElement of detailsElements) {
    detailElement.removeAttribute('open')
  }
}

/**
 * Populates warning section from Config.
 * Call on first load only.
 *
 * @returns {void}
 */
export function buildWarning (): void {
  const targetDOM = document.getElementById(domIds.warning)
  if (targetDOM !== null) {
    targetDOM.innerHTML = config?.warning ?? ''
  }
}
