/**
 * @file This file contains any functions relating to the creation of the basic result table.
 */

import { config } from '../../utils/configExport'
import { domIds } from '../../enums/enums'
import { createBaseResultTable, createTextContentForResultTable } from '../results'
import { resultData } from '../../events/resultDataCalculation'
import { createColumnAndHeaderList } from '../../utils/utils'
import type { TableConfigObject } from '../../structures/interfaces'

/**
 * Builds the basic result section and wraps it in a div.
 *
 * @returns {HTMLDivElement} The created basic result section
 */
export function buildBasicTableSection (): HTMLDivElement {
  const sectionContainer = document.createElement('div')

  const basicResultTitle = document.createElement('h2')
  basicResultTitle.textContent = config.resultsConfiguration.basicResultSection.title

  const basicResultSubtitle = document.createElement('p')
  basicResultSubtitle.textContent = config.resultsConfiguration.basicResultSection.subtitle

  const basicResultTableDiv = createBaseResultTable(domIds.basicResultDataTable, domIds.basicResultDataTableHeader, domIds.basicResultDataTableBody)

  sectionContainer.appendChild(basicResultTitle)
  sectionContainer.appendChild(basicResultSubtitle)
  sectionContainer.appendChild(basicResultTableDiv)

  return sectionContainer
}

/**
 * Populates the basic result table with data based on the configured headers and columns.
 *
 * @returns {void}
 */
export function populateBasicResultTable (): void {
  const [tableheadersColumns, tableheaders] = createColumnAndHeaderList(config.resultsConfiguration.basicResultSection)

  const htmlTable = document.getElementById(domIds.basicResultDataTable)
  const htmlTableHeader = document.getElementById(domIds.basicResultDataTableHeader)
  const htmlTableBody = document.getElementById(domIds.basicResultDataTableBody)

  // Clear whatever is existing within the table currently
  clearResultSection()

  if (htmlTable !== null && htmlTableHeader !== null && htmlTableBody !== null && resultData.clusterCount > 0) {
    const tableHeadersRow = document.createElement('tr')
    tableheaders.forEach((header: string) => {
      const headerEntry = document.createElement('th')
      headerEntry.textContent = header
      tableHeadersRow.appendChild(headerEntry)
    })
    htmlTableHeader.appendChild(tableHeadersRow)

    const tableRow = document.createElement('tr')
    tableheadersColumns.forEach((column: string) => {
      const entriesConfig: TableConfigObject = config.resultsConfiguration.basicResultSection.tableConfig[column]
      const textContent = createTextContentForResultTable(entriesConfig, resultData[column])
      const cell = document.createElement('td')
      cell.textContent = textContent
      tableRow.appendChild(cell)
    })

    htmlTable.appendChild(htmlTableHeader)
    htmlTableBody.appendChild(tableRow)
    htmlTable.appendChild(htmlTableBody)
  }
}

/**
 * Clears the content of the basic result table, including the table header and body.
 *
 * @returns {void}
 */
function clearResultSection (): void {
  const htmlTableHeader = document.getElementById(domIds.basicResultDataTableHeader)
  const htmlTableBody = document.getElementById(domIds.basicResultDataTableBody)

  if (htmlTableHeader !== null && htmlTableBody !== null) {
    htmlTableHeader.innerHTML = ''
    htmlTableBody.innerHTML = ''
  }
}
