/**
 * @file This file contains any functions relating to the year events.
 */

import type { Map, Feature } from 'ol'

import type { ConfigDefinedLayer } from '../structures/interfaces'
import { config } from '../utils/configExport'
import { checkIfVectorLayerExistsOnMap, getActiveVectorLayerFromVectorLayerId } from '../processing/vectorLayers'
import { recalculateFeaturesProperties } from '../processing/renderingCalculations'
import { styleAllSelectionsWithinSelectionDict } from './selectionEvents'
import { calculateResultData } from './resultDataCalculation'

/**
 * Updates vector layers on a map based on a change in the current year.
 *
 * This function iterates through vector layers that have year-related data,
 * calculates hydrogen demand and certainty percentage for each feature in the layer,
 * and applies scaling and opacity adjustments to the features accordingly.
 *
 * @param {Map} map - The map object to which the layers belong.
 * @param {string} currentYear - The current year for which data should be updated.
 * @returns {void}
 */
export function updateLayersFromYearChange (map: Map): void {
  const layersToChange = getLayerIdsWithYearData()
  for (const layerId of layersToChange) {
    // Check if layer exists on map before we try update it
    const layerExists = checkIfVectorLayerExistsOnMap(layerId, map)
    if (layerExists) {
      const vectorLayer = getActiveVectorLayerFromVectorLayerId(layerId, map)
      vectorLayer.getSource().getFeatures().forEach(function (feature: Feature) {
        const featureId = feature.getId()
        recalculateFeaturesProperties(feature, layerId, featureId)
      })
      styleAllSelectionsWithinSelectionDict(map)
      calculateResultData(map)
      map.render()
    }
  }
}

/**
 * Retrieves an array of layer IDs that have year-related data present in the configuration.
 *
 * This function iterates through all defined layers in the configuration object and
 * checks if the `dataStructure.year` property is true for each layer if so its ID is added to the resulting array.
 *
 * @returns {string[]} An array of layer IDs with year-related data.
 */
export function getLayerIdsWithYearData (): string[] {
  // we want to check all layers that have year present and change the underlying values
  const allLayers = config.layers

  const layersWithYearData = []

  for (const layerId in allLayers) {
    const layer: ConfigDefinedLayer = allLayers[layerId]
    if (layer.structureWithinGeoJsonProperties.year) {
      // layer has a year value present
      layersWithYearData.push(layerId)
    }
  }
  return layersWithYearData
}
