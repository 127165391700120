/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @file This is the config file - Contains specific variable definitions for each client.
 *       Not every value here needs to be populated - If the key isnt present here the config will default to using
 *       a value from the defaultConfigData UNLESS the key in the defaultConfigData has the value configKeyType.criticalConfigKey
 *       If this is the case then the code will throw an error as these keys MUST be populated.
 *
 *       The version of this file is intended to support the NGN/TfN deployment.
 */

import { FeatureType, WFSSourceType } from '../enums/enums'

export const userSuppliedConfigData: Record<string, any> = {

  // The data version for the config file - If a variable has been changed this number must be updated,
  // this includes any changes to underlying GeoJSON data.
  dataVersion: 4,

  clusterRadiusScalingFactor: 15, // Scaling factor used in displaying clusters

  mapCentre: [-1.9, 54.4], // Initial map longitude, latitude

  mapZoom: 8, // Initial map zoom

  maximumCertainty: 1, // Maximum value for certainty scaling

  minimumCertainty: 0.4, // Minimum value for certainty scaling

  nonClusterPointOpacity: 0.8, // Opacity used for styling non cluster points

  defaultYear: 2550, // Year that is used as the default for the inital state - MUST BE ONE OF THE YEAR KEYS BELOW

  // Years - this is used within our year slider & used for filtering
  years: {
    2550: {
      displayName: '2025 to 2050' // Human readable name for year period
    },
    2025: {
      displayName: '2025 to 2030'
    },
    2030: {
      displayName: '2030 to 2035'
    },
    2035: {
      displayName: '2035 to 2040'
    },
    2040: {
      displayName: '2040 to 2045'
    },
    2045: {
      displayName: '2045 to 2050'
    }
  },

  // Map Z Index - This is used to set the z index of the map (The Order that the layers are presented on the map)
  // This must be a list of layer id's. General order is: highest tier boundary, lowest tier boundary, clusters, lines, points
  mapZIndex: [
    'regions',
    'leps',
    'mets',
    'uac',
    'roads',
    'nationalGas',
    'transmissionGas',
    'intermediateGas',
    'eastCoast',
    'eastCoast3',
    'eastCoast5',
    'demand'
  ],

  // Legend Index - This is used to set the order of layers in the legend - this MUST be a list of layer id's
  legendIndex: [
    'demand',
    'nationalGas',
    'transmissionGas',
    'intermediateGas',
    'eastCoast',
    'eastCoast3',
    'eastCoast5',
    'roads',
    'regions',
    'leps',
    'mets',
    'uac'
  ],

  // Layers - This is a dict of all layers present within the application
  layers: {
    demand: { // This is the unique layer id
      displayName: 'Potential new hydrogen demand', // The human readable name of the layer - used for display purposes
      helpText: 'Areas where demand for hydrogen for heavy duty transport is possible. The potential size of the hydrogen demand is indicated by the size of the circle, and the certainty of that hydrogen demand materialising is indicated by the transparency of the circles.', // Optional human-readable hints
      featureType: FeatureType.cluster, // Flag for the type of features within the layer (used in selection calculations)
      isUsedForProximityAnalysis: true, // Flag for if the layer is used within proximity analysis
      structureWithinGeoJsonProperties: { // Definition of how the properties are structured
        year: true, // Flag for if year is present within the data structure
        modes: true, // Flag for if modes is present within the data structure
        duties: true // Flag for if duties are present within the data structure
      },
      columnsToReference: { // Properties that will be referenced in the GeoJson
        // (Takes on the format contextOfInterest: columnName)
        year: 'y',
        certaintyPercentage: 'c',
        hydrogenDemand: 'v'
      },
      displayUnits: 'kg/day', // This is a string used to display the units the layer is measured in
      primaryColor: '#0088CB', // The line colour of the layer (MUST BE HEX)
      legendSpanType: 'point', // Type of point shown in the legend can be either ['line', 'polygon', 'point']
      isWfs: false, // Flag for if the path to the data is a url or not
      isVisible: true, // Flag for deciding if the layer should be shown on startup
      path: '/data/demand.geojson' // Path / URL to the GeoJson file
    },
    regions: {
      displayName: 'Region',
      helpText: 'Subregional boundaries for the North West, North East, and Yorkshire and Humber.',
      featureType: FeatureType.boundary,
      isUsedForProximityAnalysis: false,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'RGN22NM' // Used in tooltip
      },
      primaryColor: '#1A401C',
      featureWidth: 2,
      legendSpanType: 'polygon',
      isWfs: true,
      wfsFilterProperties: {
        // WFS Source type if a new WFS source is to be used it is likely it doesnt follow the same query format so an implementation needs to
        // be written within the file wfsVectorSources.ts - A new enum entry must be added too to represent this format
        wfsType: WFSSourceType.ONS,
        boundaryFilterColumn: 'RGN22CD', // The WFS column to filter for
        boundariesToKeep: ['E12000001', 'E12000002', 'E12000003'] // The feature ID's to keep
      },
      isVisible: false,
      path: 'https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/Regions_December_2022_EN_BUC/FeatureServer/0/query?f=geojson'
    },
    leps: {
      displayName: 'Local Enterprise Partnership',
      helpText: 'Note potential hydrogen demand is only modelled for that part of Greater Lincolnshire that falls within the North of England - the Humber.',
      featureType: FeatureType.boundary,
      isUsedForProximityAnalysis: false,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'LEP22NM'
      },
      primaryColor: '#2C6C2F',
      featureWidth: 1,
      legendSpanType: 'polygon',
      isWfs: true,
      wfsFilterProperties: {
        wfsType: WFSSourceType.ONS,
        boundaryFilterColumn: 'LEP22CD',
        boundariesToKeep: ['E37000003', 'E37000007', 'E37000015', 'E37000019', 'E37000022', 'E37000025',
          'E37000034', 'E37000054', 'E37000058', 'E37000060', 'E37000061', 'E37000062']
      },
      isVisible: false,
      path: 'https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/LEP_DEC_2022_EN_BGC_V2/FeatureServer/0/query?f=geojson'
    },
    mets: {
      displayName: 'Metropolitan County',
      helpText: 'Local government boundaries.',
      featureType: FeatureType.boundary,
      isUsedForProximityAnalysis: false,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'mcty18nm'
      },
      primaryColor: '#409D44',
      featureWidth: 1,
      legendSpanType: 'polygon',
      isWfs: true,
      wfsFilterProperties: {
        wfsType: WFSSourceType.ONS,
        boundaryFilterColumn: 'MCTY18CD',
        boundariesToKeep: ['E11000001', 'E11000002', 'E11000003', 'E11000006', 'E11000007']
      },
      isVisible: false,
      path: 'https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/Metropolitan_Counties_December_2018_GCB_in_England_2022/FeatureServer/0/query?f=geojson'
    },
    uac: {
      displayName: 'County or Unitary Authority',
      helpText: 'Local government boundaries.',
      featureType: FeatureType.boundary,
      isUsedForProximityAnalysis: false,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'CTYUA23NM'
      },
      primaryColor: '#5FD564',
      featureWidth: 1,
      legendSpanType: 'polygon',
      isWfs: true,
      wfsFilterProperties: {
        wfsType: WFSSourceType.ONS,
        boundaryFilterColumn: 'CTYUA23CD',
        boundariesToKeep: ['E06000001', 'E06000002', 'E06000003', 'E06000004', 'E06000005', 'E06000006', 'E06000007', 'E06000008',
          'E06000009', 'E06000010', 'E06000011', 'E06000012', 'E06000013', 'E06000014',
          'E06000047', 'E06000049', 'E06000050', 'E06000057', 'E06000063', 'E06000064',
          'E06000065', 'E08000001', 'E08000002', 'E08000003', 'E08000004', 'E08000005',
          'E08000006', 'E08000007', 'E08000008', 'E08000009', 'E08000010', 'E08000011',
          'E08000012', 'E08000013', 'E08000014', 'E08000015', 'E08000016', 'E08000017',
          'E08000018', 'E08000019', 'E08000021', 'E08000022', 'E08000023', 'E08000024',
          'E08000032', 'E08000033', 'E08000034', 'E08000035', 'E08000036', 'E08000037',
          'E10000017']
      },
      isVisible: false,
      path: 'https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/Counties_and_Unitary_Authorities_May_2023_UK_BGC/FeatureServer/0/query?f=geojson'
    },
    nationalGas: {
      displayName: 'National Transmission System',
      helpText: 'The primarily inter-regional gas distribution network operated by National Gas, typically between 40 and 90 bar.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'PIPE_NAME'
      },
      primaryColor: '#7D372D',
      featureWidth: 4,
      legendSpanType: 'line', // Data is technically polygon, but legend should indicate line
      isWfs: false,
      isVisible: false,
      path: '/data/national-gas.geojson'
    },
    transmissionGas: {
      displayName: 'Local Transmission System',
      helpText: 'The strategic backbone of gas distribution for the more local Gas Distribution Network Operators, typically between 7 and 16 bar.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'NAME'
      },
      primaryColor: '#B85143',
      featureWidth: 3,
      legendSpanType: 'line',
      isWfs: false,
      isVisible: false,
      path: '/data/transmission-gas.geojson'
    },
    intermediateGas: {
      displayName: 'Intermediate Gas Network',
      helpText: "The Gas Distribution Network Operators' more local strategic distribution pipelines, typically between 2 and 7 bar.",
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'NAME'
      },
      primaryColor: '#FE7F6E',
      featureWidth: 2,
      legendSpanType: 'line',
      isWfs: false,
      isVisible: false,
      path: '/data/intermediate-gas.geojson'
    },
    roads: {
      displayName: 'Road (strategic and major)',
      helpText: 'Strategic and major road networks as defined by Transport for the North.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'id'
      },
      primaryColor: '#6167C9',
      featureWidth: 2,
      legendSpanType: 'line',
      isWfs: false,
      isVisible: false,
      path: '/data/roads.geojson'
    },
    eastCoast: {
      displayName: 'East Coast core proposal',
      helpText: 'Proposed main link between the Humber and the North East, via West Yorkshire. Project aims to repurpose parts of the higher-pressure gas transmission system direct to hydrogen.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'name'
      },
      primaryColor: '#60491B',
      featureWidth: 4,
      legendSpanType: 'line', // Data is technically polygon, but legend should indicate line
      isWfs: false,
      isVisible: false,
      path: '/data/east-coast.geojson'
    },
    eastCoast3: {
      displayName: 'East Coast stage 3 proposal',
      helpText: 'Proposed feeder pipelines, primarily between major industrial users and the main link. Project aims to repurpose parts of the higher-pressure gas transmission system direct to hydrogen.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'name'
      },
      primaryColor: '#8B6C27',
      featureWidth: 2,
      legendSpanType: 'line',
      isWfs: false,
      isVisible: false,
      path: '/data/east-coast-3.geojson'
    },
    eastCoast5: {
      displayName: 'East Coast stage 5 proposal',
      helpText: 'Proposed future network expansion, primarily towards Cumbria. Project aims to repurpose parts of the higher-pressure gas transmission system direct to hydrogen.',
      featureType: FeatureType.lineString,
      isUsedForProximityAnalysis: true,
      structureWithinGeoJsonProperties: {
        year: false,
        modes: false,
        duties: false
      },
      columnsToReference: {
        displayNameColumn: 'Name'
      },
      primaryColor: '#C89F38',
      featureWidth: 3,
      legendSpanType: 'line', // Mixed line and polygon data, but fundamentally linear
      isWfs: false,
      isVisible: false,
      path: '/data/east-coast-5.geojson'
    }
  },

  advancedControl: {
    controlText: { // Modes section
      title: 'Modes included',
      helpText: 'Include only demand clusters that contain at least one of the selected modes of transport'
    },
    modes: { // Extendible for additional modes
      hgv: { // Unqiue html ID for checkbox
        displayName: 'Heavy Goods Vehicles', // Display name for checkbox
        description: 'Heavy Goods Vehicles are those that carry goods and are over 3.5 tonnes in weight. The vehicles included here are articulated, the vast majority of 44 tonne capacity.',
        propertyColumn: 'h', // Column checkbox should interact with
        isVisible: true // Flag for if the mode should be on by default
      },
      bus: {
        displayName: 'Local buses',
        description: 'Scheduled local bus services, excluding coach networks operated as Flixbus, Megabus or National Express.',
        propertyColumn: 'b',
        isVisible: true
      },
      train: {
        displayName: 'Trains',
        description: 'Heavy rail, both passenger and freight, including trains that carry railway engineering materials, but not the use of mobile machinery to maintain infrastructure.',
        propertyColumn: 't',
        isVisible: true
      }
    },
    definedDuties: { // Not used for the advanced control however used as a lookup to convert the property data names into human readable ones
      c: {
        displayName: 'City buses',
        description: 'Those core high-frequency urban duties that may be challenging to decarbonise with battery electric buses. On balance, many alternatives to hydrogen are relatively attractive: high frequency may favour investment in opportunity charging, and proximity to depot favours extra vehicles with daytime charging.'
      },
      d: { // name of duty found within the cluster layers
        displayName: 'Domestic lorries', // Readable name used for output
        description: 'High mileage domestic duties, either with 2 drivers (and thus no natural stops to recharge en-route) or involving infrequent long-distance journeys. In both cases operators could be willing to pay a premium for the added operational flexibility granted by hydrogen. However, operators are unlikely to be prepared to pay more than an extra 30%, beyond which less convenient forms of battery operation will tend to be more viable. Domestic lorries are thus likely to be priced out of the market by the cost of green hydrogen production.' // Description found within the hidden row of the duty summary element
      },
      e: {
        displayName: 'Railway engineering',
        description: 'Trains operated to move railway maintenance materials and equipment around the network, not the operation of that equipment to maintain infrastructure (which is outside the scope of this modelling). About a third of these trains and relatively light or short distance, so likely to convert to battery operation. The remainder are far heavier and mimic patterns of Trainload bulk and metals, which better suit hydrogen.'
      },
      f: {
        displayName: 'Railfreight distribution',
        description: 'Primarily intermodal, especially maritime container, freight trains. Cargos tend to be lighter than Trainload bulk trains, more likely to pass under overhead-electrified sections of track, and loads more evenly balanced in each direction, but the distances travelled tend to be further. While hydrogen is a realistic possibility for many of these hauls, especially by the 2040s when much of the existing diesel locomotive fleet will become life-expired, on some routes bimodal battery locomotives will be able to bridge gaps in track electrification.'
      },
      i: {
        displayName: 'International lorries',
        description: 'International duties, reflecting both long journey distances and the likelihood, or otherwise, of hydrogen becoming a more widespread truck fuel in continental Europe. While European policy has been supportive of hydrogen for international lorries, the deployment of high-power rapid charging stations is gaining momentum sooner, and ultimately at lower operating cost. The possibility of more trailer-only sea crossings, with alternative-powered tractors used to haul in Britain and in continental Europe, adds a further degree of uncertainty.'
      },
      m: {
        displayName: 'Regional passenger trains',
        description: 'Mid-distance, excluding primarily local or suburban services, typically between regional towns and cities using short two or three carriage units and operating up to 90 miles per hour. These services are likely to favour bimodal battery operation over hydrogen. Most of this fleet is already approaching the end of its working life, and neither technology is likely to be optimised soon enough, nor cheap enough to suit what are often subsidised operations. This all adds to the uncertainty of decarbonisation pathway for regional passenger trains.'
      },
      n: {
        displayName: 'Interurban buses',
        description: 'Those duties on routes to a regional centre from outside that centre that may be challenging to decarbonise with battery electric buses. While primarily serving urban markets, these relatively high frequency routes often form the backbone of services in rural areas. On balance, hydrogen is a strong contender, especially where roads are narrow (thus not suited to tri-axle buses), and frequencies are modest (thus not suited to opportunity charging).'
      },
      r: {
        displayName: 'Rural buses',
        description: 'Those local rural or small town duties that will be challenging to decarbonise with battery electric buses: Duties where any BEB would require charging at least once during the day, time which there is no slack for in the existing route vehicle requirement. Dispersed operational patterns will not favour fixed vehicle charging alternatives, while roads tend not to favour the use of longer vehicles, both factors strengthening the case for hydrogen fuel cell vehicles.'
      },
      s: {
        displayName: 'Suburban buses',
        description: 'Those secondary urban duties (on routes operated at lower frequency than city buses) that may be challenging to decarbonise with battery electric buses. Lower frequencies than city routes weaken the case for opportunity charging infrastructure, while roads tend not to favour the use of longer vehicles. However, like city duties, suburban duties tend to operate relatively close to depots, favouring the option of extra BEBs with daytime charging, not necessarily hydrogen.'
      },
      t: {
        displayName: 'Trainload bulk and metals',
        description: 'Primarily aggregates, including all the heaviest trains operated in Britain. These typically travel shorter distances than Railfreight distribution, with an empty return haul as low as a quarter of the net weight of the outbound loaded train. The relatively high power requirements of these trains, combined with limited running under overhead wires, make a strong case for hydrogen. Hydrogen may however raise operating cost to the point where alternative, especially maritime shipped, sources of material become more cost effective for customers.'
      },
      x: {
        displayName: 'Intercity passenger trains',
        description: 'Long-distance trains operating at over a hundred miles per hour, with routes centred on London or Birmingham. Services operated primarily under overhead are likely to favour bimodal battery operation over hydrogen. Services which, even after Midland Mainline electrification, are still primarily operating on unelectrified track are likely to be more cost effective to operate bimodally with hydrogen fuel cells. These primarily operate to or from southwest England, so there is a risk that further track electrification in that region could tilt the entire fleet away from hydrogen towards bimodal battery.'
      }
    },
    customSliders: { // Custom sliders can be defined in here
      // If a new slider is added the sliderId MUST be added to the enum found in ./src/enum/enums.ts
      certainty: { // sliderId - Unqiue name used for the html ID for the slider
        displayName: 'Certainty minimum', // Human readable name for the slider
        helpText: 'Include only potential demands judged with at least this certainty of needing hydrogen', // Tool tip displayed for the slider
        min: 0, // Minimum possible value of the sldier
        max: 50, // Maximum possible value of the slider
        increments: 10, // Increments for the slider
        defaultValue: 0, // Default value the slider starts at
        displayUnits: '%' // Visual display units - Used only for visual purposes
      },
      range: {
        displayName: 'Range maximum',
        helpText: 'Include only clusters within this range of the selected infrastructure during analysis',
        min: 0.1,
        max: 10,
        increments: 0.1,
        defaultValue: 3,
        displayUnits: 'kilometres'
      }
    }
  },

  // Non-result guidance text, in display order.
  // Each entry is rendered as an expandable block, where only title is visible in normal use.
  // The first entry is an exception, visible on first use - this should be used for at least introductory text.
  // The content may contain rich HTML, but no Javascript functions.
  guidance: [
    {
      title: 'Introduction',
      content: `<ul class="logos"><li><a href="https://www.northerngasnetworks.co.uk/" 
      title="Northern Gas Networks" target="_blank"><img src="/ngn.svg" alt="Northern Gas Networks" 
      /></a></li><li><a href="https://www.erm.com/" 
      title="ERM, formerly Element Energy" target="_blank"><img src="/erm.svg" alt="ERM" 
      /></a></li><li><a href="https://transportforthenorth.com/" 
      title="Transport for the North" target="_blank"><img src="/tfn.svg" alt="Transport for the North" 
      /></a></li></ul>
      <h1>North of England Hydrogen Mobility Visualiser</h1>
      <p>This visualiser is intended to help build partnerships between potential users of hydrogen within the transport sector
      in the north of England, and relate those local potential demand clusters to strategic infrastructure.</p>
      <p>The blue circles on the map identify the approximate areas and volumes with realistic potential for hydrogen refuelling.
      The visualiser's analysis tools can be used to help further understand the risks and opportunities
      associated with these potential transport sector hydrogen demands.</p>
      <p><strong>To get started, please read the <a href="/user-guide.pdf" target="_blank">User Guide</a>.</strong>
      This includes an explanation of the tool's analysis features and an overview of the method behind the data displayed.</p>
      <p>To better understand the evidence presented, please read the
      <a href="/modelling-method.pdf" target="_blank">Modelling Method</a>.
      This outlines decarbonisation issues and prevailing pathways, details the modelling method and assumptions,
      rationalises the certainties, and explains why several modes of transport that have not been included.</p>`
    },
    // Extendable here. Legal should be placed at the bottom.
    {
      title: 'Terms of use',
      content: `<p>The data produced by this tool can be used by third parties as long as the source is clearly attributed to
      "NGN, TfN and ERM". For our terms of use, please see the
      <a href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
      target="_blank">Open Government Licence</a>.</p>
      <p>Tool, data modelling methodology, and documentation &copy; Copyright 2024 by
      <a href="https://www.erm.com/" title="Formerly Element Energy" target="_blank">The ERM International Group Limited (ERM)</a>.
      All Rights Reserved.</p>
      <p>Map content may include:</p>
      <ul>
      <li>Base map sourced from <a href="https://www.openstreetmap.org/copyright"
      target="_blank">OpenStreetMap</a> contributors.</li>
      <li>Administrative and demographic geography sourced from the
      <a href="https://www.ons.gov.uk/" target="_blank">Office for National Statistics</a> licensed under the 
      <a href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
      target="_blank">Open Government Licence v.3.0</a>.</li>
      <li>Administrative and highway geography contains <a href="https://www.ordnancesurvey.co.uk/"
      target="_blank">Ordnance Survey</a> data &copy; Crown copyright and database right 2023.</li>
      <li>Gas network maps include data &copy; <a href="https://www.nationalgas.com/" target="_blank">National Gas Transmission</a>,
      &copy; <a href="https://www.northerngasnetworks.co.uk/"target="_blank" >Northern Gas Networks</a>,
      &copy; <a href="https://cadentgas.com/" target="_blank">Cadent Gas Ltd</a> and
      &copy; <a href="https://www.eastcoasthydrogen.co.uk/" target="_blank">East Coast Hydrogen</a>.</li>
      </ul>
      <p>Modelled potential hydrogen demand integrates, but does not reproduce, multiple additional data sources including:</p>
      <ul>
      <li>Department for Transport's <a href="https://data.bus-data.dft.gov.uk/" target="_blank">Bus Open Data Service</a> and
      Driver and Vehicle Licensing Agency open data, augmented by fleet data from
      <a href="https://bustimes.org/" target="_blank">bustimes.org</a> contributors.</li>
      <li><a href="https://www.networkrail.co.uk/" target="_blank">Network Rail</a> open data,
      augmented by open location data from <a href="https://railmap.azurewebsites.net/" target="_blank">GB Railway Data Ltd</a>.</li>
      </ul>
      <p>We do not guarantee the accuracy of the data or method herein.
      Source data may change over time. Potential sources of hydrogen demand are inherently uncertain.</p>
      <p>The tool is intended to start a conversation between potential partners, not predict the outcome of any partnership.
      The modelled data presented here is not a predictor of the future demand, and
      should not be used as the sole justification for investment decisions.</p>`
    }
  ]

}
