/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @file This is the config export file - This file loops through the keys found within the defaultConfigData in default.ts
 *       it then checks that the key is present in userSuppliedConfigData if the key isnt present the config will default
 *       to the value found within defaultConfigData UNLESS the value within defaultConfigData is configKeyType.criticalConfigKey
 *       if the vlaue is a critical config key the code will log out an error.
 */
import { userSuppliedConfigData } from '../config/config'
import { defaultConfigData } from '../config/defaults'
import { configKeyType } from '../enums/enums'

/**
 * Chooses a default value if the provided configuration value is undefined.
 *
 * @param configValue - The configuration value to check.
 * @param defaultValue - The default value to use if the configValue is undefined.
 * @param {string} key - The key associated with the configuration value (used for error message).
 * @returns {any} - The chosen value, which is either configValue or defaultValue.
 * @throws {Error} - Throws an error if configValue is undefined and defaultValue is configKeyType.criticalConfigKey.
 */
function chooseDefaultIfNeeded (configValue: any, defaultValue: any, key: string): any {
  // Filter for empty json as to check if they are empty a different process is needed
  if (configValue === undefined) {
    if (defaultValue === configKeyType.criticalConfigKey) {
      throw new Error(`Please make sure the key : ${key} is populated`)
    } else {
      return defaultValue
    }
  } else {
    return configValue
  }
}

export const config: Record<string, any> = {}
for (const key in (defaultConfigData)) {
  // Loop through the keys in user supplied config and compare with defaults
  if (Object.prototype.hasOwnProperty.call(defaultConfigData, key)) {
    const configValue = userSuppliedConfigData[key]
    const defaultValue = defaultConfigData[key]
    const value = chooseDefaultIfNeeded(configValue, defaultValue, key)
    config[key] = value
  }
}
