/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @file This is the map file it contains any map related functions
 */

import { Map, View } from 'ol'
import { defaults as defaultControls } from 'ol/control'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { fromLonLat } from 'ol/proj'
import type VectorSource from 'ol/source/Vector'

import { buildControlFrame } from '../controls/controls'
import { controlRole, domIds } from '../enums/enums'
import { config } from '../utils/configExport'
import { applyToolTipOverlay } from './mapToolTip'

/**
 * Creates a base map with specified controls, layers, and view configuration.
 *
 * @returns {Map} The created base map instance.
 */
export function createBaseMap (vectorSourceDict: Record<string, VectorSource>): Map {
  const map = new Map({
    controls: defaultControls({
      attribution: false /* Handled within Legal */
    }),
    layers: [
      new TileLayer({
        className: 'baseMap',
        name: domIds.map,
        source: new OSM()
      })
    ],
    target: domIds.map,
    view: new View({
      center: fromLonLat(config.mapCentre),
      zoom: config.mapZoom
    })
  })

  applyToolTipOverlay(map)

  map.addControl(buildControlFrame(controlRole.advanced, map, vectorSourceDict))
  map.addControl(buildControlFrame(controlRole.layers, map, vectorSourceDict))
  map.addControl(buildControlFrame(controlRole.select, map, vectorSourceDict))
  map.addControl(buildControlFrame(controlRole.years, map, vectorSourceDict))

  return map
}
