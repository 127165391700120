/**
 * @file This file contains any functions relating to the advanced section controls.
 */

import { config } from '../utils/configExport'
import type { DefinedModesConfig, SliderConfig } from '../structures/interfaces'
import { createCheckBoxLabel } from './layerControls'
import { updateClustersUponModeSelection } from '../events/modeEvents'
import { sliderIds } from '../enums/enums'
import { updateClustersOnMap } from '../events/sliderEvents'

/**
 * Builds and appends an advanced section to the specified DOM parent element.
 *
 * Within the "Advanced" section, it includes cluster modes and custom sliders.
 *
 * @param {HTMLDivElement} domParent - The HTML div element to which the advanced section will be appended.
 * @param {Map} map - The Map object that the controls interact with.
 * @returns {void}
 *
 */
export function buildAdvancedSection (domParent: HTMLDivElement, map: Map): void {
  const fragment = new DocumentFragment()
  const advancedSectionList = document.createElement('ul')

  const modeListItem = document.createElement('li')
  const detailsElement = createModesDetailSummaryPair(map)
  modeListItem.appendChild(detailsElement)
  advancedSectionList.appendChild(modeListItem)
  const sliders = config.advancedControl.customSliders
  for (const sliderId in sliders) {
    const slider: SliderConfig = sliders[sliderId]
    const rangeSlider = createRangeSummaryPair(slider, sliderIds[sliderId], map)
    const listItem = document.createElement('li')
    listItem.appendChild(rangeSlider)
    advancedSectionList.appendChild(listItem)
  }

  fragment.appendChild(advancedSectionList)
  domParent.appendChild(fragment)
}

/**
 * This function creates a collapsible details element with a summary text and a list of checkboxes
 * corresponding to different modes provided in the configuration.
 *
 * @param {Map} map - The Map object that the controls interact with.
 * @returns {DocumentFragment} - The created HTML details element.
 *
 */
function createModesDetailSummaryPair (map: Map): DocumentFragment {
  const fragment = new DocumentFragment()

  const detailsElement = document.createElement('details')
  const summaryElement = document.createElement('summary')
  summaryElement.textContent = config.advancedControl.controlText.title
  const container = document.createElement('ul')
  detailsElement.appendChild(summaryElement)

  const modesDict = config.advancedControl.modes

  for (const modeId in modesDict) {
    const checkBoxContainer = document.createElement('li')
    const modeConfig: DefinedModesConfig = modesDict[modeId]
    const checkBox = createModeCheckboxEntry(modeId, modeConfig)
    const checkBoxLabel = createCheckBoxLabel(modeConfig.displayName, modeId)

    checkBox.addEventListener('change', function () { updateClustersUponModeSelection(map) })

    checkBoxContainer.appendChild(checkBox)
    checkBoxContainer.appendChild(checkBoxLabel)
    container.appendChild(checkBoxContainer)
  }

  detailsElement.appendChild(container)

  if (config.advancedControl.controlText.helpText !== undefined) {
    detailsElement.title = config.advancedControl.controlText.helpText
    const modeHelpText = document.createElement('div')
    modeHelpText.innerText = config.advancedControl.controlText.helpText
    modeHelpText.className = 'controlHelp'
    detailsElement.appendChild(modeHelpText)
  }

  fragment.appendChild(detailsElement)

  return fragment
}

/**
 * This function creates a checkbox input element that represents a specific cluster mode
 *
 * @param {string} modeId - The unique identifier for the mode.
 * @param {DefinedModesConfig} modeConfig - The configuration object for the mode.
 * @returns {HTMLInputElement} - The created HTML checkbox input element.
 *
 */
function createModeCheckboxEntry (modeId: string, modeConfig: DefinedModesConfig): HTMLInputElement {
  const checkBox = document.createElement('input')
  checkBox.type = 'checkbox'
  checkBox.id = modeId
  checkBox.value = modeConfig.propertyColumn
  checkBox.checked = modeConfig.isVisible
  return checkBox
}

/**
 * Creates and returns an HTML details element containing a summary and a range slider.
 *
 * @param {SliderConfig} slider - The configuration object for the range slider.
 * @param {string} sliderId - The unique identifier for the range slider.
 * @returns {DocumentFragment} - The created HTML details element.
 *
 */
function createRangeSummaryPair (slider: SliderConfig, sliderId: string, map: Map): DocumentFragment {
  const fragment = new DocumentFragment()

  const detailsElement = document.createElement('details')
  const summaryElement = document.createElement('summary')

  detailsElement.id = `details_${sliderId}`
  summaryElement.textContent = slider.displayName

  detailsElement.appendChild(summaryElement)

  const sliderContainer = document.createElement('div')
  sliderContainer.className = 'controlFrame'

  const rangeSlider = document.createElement('input')
  rangeSlider.type = 'range'
  rangeSlider.min = slider.min.toString()
  rangeSlider.max = slider.max.toString()
  rangeSlider.id = sliderId
  rangeSlider.step = slider.increments.toString()
  rangeSlider.value = slider.defaultValue.toString()
  const rangeValue = document.createElement('div')

  const initialValue = `${slider.defaultValue} ${slider.displayUnits}`
  rangeValue.innerHTML = initialValue
  rangeSlider.oninput = function () {
    const text = `${this.value} ${slider.displayUnits}`
    rangeValue.innerHTML = text
  }

  rangeSlider.onchange = function () { updateClustersOnMap(map) }

  sliderContainer.appendChild(rangeSlider)
  sliderContainer.appendChild(rangeValue)

  detailsElement.appendChild(sliderContainer)

  if (slider.helpText !== undefined) {
    detailsElement.title = slider.helpText
    const rangeHelpText = document.createElement('div')
    rangeHelpText.innerText = slider.helpText
    rangeHelpText.className = 'controlHelp'
    detailsElement.appendChild(rangeHelpText)
  }

  fragment.appendChild(detailsElement)

  return fragment
}
