/**
 * @file Functions that build and manage user interface controls. - it imports in the relevant functions
 *       to then build the controls where applicable
 */
import Control from 'ol/control/Control'

import { controlRole, domIds } from '../enums/enums'
import type { ControlFrameOptions } from '../structures/interfaces'
import { buildYearSlider } from './yearControls'
import { buildLayerSelectbox } from './layerControls'
import { buildAdvancedSection } from './advancedControls'
import { buildSelectModeBox } from './selectControls'

/**
 * Defines control-frame-specific variables:
 * key each by a controlRole, value each as a ControlOptions.
 */
const controlFrameSpecifications: Partial<Record<controlRole, Partial<ControlFrameOptions>>> = {
  [controlRole.advanced]: {
    id: domIds.advancedControl
  },
  [controlRole.layers]: {
    id: domIds.layersControl
  },
  [controlRole.select]: {
    classNames: 'ol-control', // Inherits look and feel of an OL control frame
    id: domIds.selectControl
  },
  [controlRole.years]: {
    classNames: 'ol-control',
    id: domIds.yearsControl,
    yearId: 0

  }
}

/**
 * Builds and populates main control frames.
 *
 * @param {controlRole} role - controlRole enum value to build.
 * @param {Map} map - The Map object that the controls interact with.
 * @param {Record<string, VectorSource>} vectorSourceDict - The vector source dict that contains initialized vector sources
 * @return {Control} - OL Control object to be added to the map: controls.
 */
export function buildControlFrame (role: controlRole, map: Map, vectorSourceDict: Record<string, VectorSource>): Control {
  const specification = controlFrameSpecifications[role] ?? {}
  // OL docs favour createElement, not createDocumentFragment, so:
  const domParent = document.createElement('div')

  if ('id' in specification) {
    domParent.id = String(specification.id) // TS enums don't strictly force a string
  }

  let className = 'custom-control ol-unselectable'
  if ('classNames' in specification && specification.classNames !== '') {
    className += ' ' + specification.classNames
  }
  domParent.className = className

  switch (role) {
    case controlRole.years:
      buildYearSlider(domParent, specification, map)
      break
    case controlRole.layers:
      buildLayerSelectbox(domParent, map, vectorSourceDict)
      break
    case controlRole.advanced:
      buildAdvancedSection(domParent, map)
      break
    case controlRole.select:
      buildSelectModeBox(domParent, map)
      break
  }
  const control = new Control({ element: domParent })
  return control
}
